import { BoxContentPicker } from '@socialchorus/box-components';
import React, { ComponentProps } from 'react';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { BoxIntegrationData } from 'models/box-integration';
import { FormModal } from 'DesignSystem/Components/Modal';
import styles from './box.module.css';

type PickerModalProps = Pick<
  ComponentProps<typeof BoxContentPicker>,
  'type' | 'folderId'
> & {
  entityText?: string;
  description?: string;
  onSubmit: (resource: BoxIntegrationData) => void;
  onCancel: () => void;
};

export const PickerModal: React.FC<PickerModalProps> = ({
  type,
  folderId = '0',
  entityText,
  description,
  onSubmit,
  onCancel,
}) => {
  const { id: programId } = useProgram();
  const token = (useFeatureFlagsQuery(
    programId,
    'License.Integration.BoxKnowledgeManagement.TempDevToken'
  ).data?.value || '') as string;

  return (
    <FormModal
      onSubmit={() => {}}
      onCancel={onCancel}
      actionText="select"
      entityText={entityText ?? 'box content'}
      description={description}
      contentPadding="0"
      hideFooter
    >
      <div className={styles.PickerWrapper}>
        {token && (
          <BoxContentPicker
            type={type}
            token={token}
            folderId={folderId}
            logoUrl="box"
            onCancel={onCancel}
            canUpload={false}
            canCreateNewFolder={false}
            onChoose={(choices) => {
              const choice = choices[0]; // right now box content picker only supports selecting one item
              onSubmit({
                file_url: choice.authenticated_download_url,
                name: choice.name,
                extension:
                  choice.type === 'file' ? choice.name.split('.').pop() : '',
                resource_id: choice.id,
                resource_type: choice.type,
                description: choice.description,
                call_to_action: choice.call_to_action,
                image: choice.image,
              });
            }}
          />
        )}
      </div>
    </FormModal>
  );
};
