import * as React from 'react';
import cx from 'classnames';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Tooltip } from '../Tooltip';
import { HoverTooltip } from '../HoverTooltip';
import styles from './fieldset.module.css';

type PropsType = {
  label: string | React.ReactNode;
  help?: React.ReactNode;
  className?: string;
  tooltip?: string | React.ReactNode;
  labelFor?: string;
  isLoading?: boolean;
  error?: string;
};

export const Field: React.FC<PropsType> = (props) => {
  const {
    isLoading,
    label,
    help,
    tooltip,
    className,
    children,
    labelFor,
    error,
  } = props;

  const dropdown = React.useCallback(() => <Tooltip description={tooltip} />, [
    tooltip,
  ]);

  return (
    <div className={cx(styles.field, className)}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>
          <label htmlFor={labelFor}>{label}</label>
          {tooltip && <HoverTooltip content={dropdown} align="right" />}
        </div>
        <div className={styles.help}>{help}</div>
      </div>

      <div className={styles.input}>
        {children}
        {isLoading && (
          <div data-test="fieldset-field-spinner" className={styles.loading}>
            <LoadingSpinner />
          </div>
        )}

        {error && (
          <div className={styles.error}>
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};
