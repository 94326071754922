import { useProgram } from 'contexts/program';
import { Design } from 'models/design';
import { Post, buildPreviewPermalink } from 'models/publisher/post';
import { sendTestJourneyCommunication } from 'services/api-journey';
import { sendTestEmail } from 'services/api-post';
import { useFieldVariables } from './publisher/useFieldVariables';

type SendTestEmailProps = {
  subject: string;
  previewText: string;
  userIds: number[];
  preferOutlook365: boolean;
  post?: Post;
  design?: Design;
  type: 'campaign' | 'journey';
  onSuccess?: () => void;
};

export const useSendTestEmail: (
  props: SendTestEmailProps
) => { sendEmail: () => void; sendEmailRequest: () => Promise<void> } = ({
  subject,
  previewText,
  userIds,
  preferOutlook365,
  post,
  design,
  onSuccess,
  type,
}) => {
  const { id: programId } = useProgram();
  const { fromPost } = useFieldVariables();
  let sendEmailRequest = () => Promise.resolve();
  if (type === 'campaign' && post) {
    sendEmailRequest = () => {
      return sendTestEmail(
        subject,
        previewText,
        post,
        { ...fromPost(post), content_permalink: buildPreviewPermalink(post) },
        programId,
        userIds,
        preferOutlook365
      );
    };
  } else if (type === 'journey' && design) {
    sendEmailRequest = () => {
      return sendTestJourneyCommunication(
        subject,
        previewText,
        design,
        programId,
        userIds,
        preferOutlook365
      );
    };
  } else {
    throw new Error('Unexpected arguments for useSendTestEmail');
  }
  const sendEmail = () => {
    if (userIds && userIds.length > 0) {
      const request = sendEmailRequest();
      request.then(() => {
        if (onSuccess) onSuccess();
      });
    }
  };
  return {
    sendEmail,
    sendEmailRequest,
  };
};
