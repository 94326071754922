import React from 'react';
import { areVariablesValid } from 'models/notification';
import { InputContainer } from './InputContainer';
import { DropdownContainer } from './DropdownContainer';
import { setCaretOffsetWithin } from './lib';

type Props = {
  setText: (text: string) => void;
  text: string;
  variables: { key: string; description: string }[];
  channel?: 'email' | 'push' | 'assistant';
  invalid?: boolean;
  placeholder?: string;
  autoFillText?: string;
  focusRef?: React.RefObject<HTMLElement>;
  disabled?: boolean;
  className?: string;
  maxSize?: number;
  classNames?: string;
  disableSuggest?: boolean;
  clearPadding?: boolean;
  variableTextClassName?: string;
  onCaretMoved?: (value: number) => void;
};

type State = {
  matchingText: string;
  onSelectVariable: (key: string) => void;
  channel?: string;
  text: string;
  variables: { key: string; description: string }[];
  isValid: boolean;
  errorText: string;
};

export class VariableTextInput extends React.Component<Props, State> {
  static getDerivedStateFromProps(
    props: Props,
    prevState: Partial<State>
  ): State {
    return {
      matchingText: '', // defaults
      onSelectVariable: () => null,
      isValid: true,
      errorText: '',
      ...prevState, // overrides with previous state
      channel: props.channel, // updated state attributes
      text: props.text,
      variables: props.variables,
    };
  }

  private abort = false;

  private ref: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = VariableTextInput.getDerivedStateFromProps(props, {});
    this.showVariables = this.showVariables.bind(this);
    this.moveTo = this.moveTo.bind(this);
    this.hideVariables = this.hideVariables.bind(this);
    this.onAnywhereClick = this.onAnywhereClick.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('click', this.onAnywhereClick);
    const { variables } = this.props;
    if (variables.length > 0) {
      this.validateInput();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { variables, text } = this.props;
    if (variables.length !== prevProps.variables.length) {
      this.validateInput();
    } else if (prevProps.text !== text) {
      this.validateInput();
    }
  }

  componentWillUnmount(): void {
    this.abort = true;
    window.removeEventListener('click', this.onAnywhereClick);
  }

  onAnywhereClick(event: MouseEvent): void {
    if (!this.abort) {
      this.hideVariables();
      const target = (event.target as unknown) as HTMLElement;
      if (this.ref && this.ref.contains(target)) {
        // If the user clicked on a span or div (input or pill), then
        // it will naturally take focus. But if they missed, and it fell
        // through to the parent element, we'll put the cursor at the very
        // end of the input for them. -vti-{clickable,channel}
        if (target.className.indexOf('-vti-') === -1) {
          const last = this.ref.querySelector('.-vti-clickable:last-child');
          if (last) {
            setCaretOffsetWithin((last as HTMLElement).innerText.length, last);
          }
        }
      }
    }
  }

  validateInput(): void {
    const { text } = this.state;
    const { maxSize, variables } = this.props;
    const allowedVariables = variables.map((v) => v.key);
    const isInvalid = !areVariablesValid(text, allowedVariables);

    if (isInvalid) {
      this.setState({ isValid: false, errorText: 'Variable is not valid' });
    } else if (maxSize && text.length > maxSize) {
      this.setState({ isValid: false, errorText: `Max length is ${maxSize}` });
    } else {
      this.setState({ isValid: true, errorText: '' });
    }
  }

  moveTo({
    focusIndex,
    caretIndex,
  }: {
    focusIndex?: number;
    caretIndex?: number;
  }): void {
    setTimeout(() => {
      if (!this.ref) return;
      // css is 1-based. focusIndex's are 0-based
      const elem = this.ref.querySelector(
        `.-vti-clickable:nth-child(${(focusIndex || 0) + 1})`
      ) as HTMLElement;
      if (elem) {
        elem.focus();
        // This is when an input moves to a variable pill, there is no caret
        if (caretIndex !== undefined) {
          let caret = caretIndex;
          if (caret === -1) caret = elem.innerText.length;
          setCaretOffsetWithin(caret, elem);
        }
      }
    }, 1);
  }

  hideVariables(): void {
    this.setState({ matchingText: '' });
  }

  showVariables(
    matchingText: string,
    onSelectVariable: (key: string) => void
  ): void {
    const { disableSuggest } = this.props;
    if (!disableSuggest) {
      this.setState({ matchingText, onSelectVariable });
    }
  }

  render(): JSX.Element | null {
    const {
      channel,
      text,
      variables,
      matchingText,
      onSelectVariable,
      errorText,
      isValid,
    } = this.state;
    const {
      setText,
      invalid = false,
      placeholder = '',
      autoFillText = '',
      focusRef,
      disabled = false,
      classNames,
      clearPadding,
      variableTextClassName,
      onCaretMoved,
    } = this.props;
    return (
      <div
        ref={(_) => {
          this.ref = _;
        }}
      >
        <InputContainer
          channel={channel}
          text={text}
          setText={setText}
          showVariables={this.showVariables}
          variables={variables}
          invalid={invalid || !isValid}
          moveTo={this.moveTo}
          placeholder={placeholder}
          autoFillText={autoFillText}
          focusRef={focusRef}
          disabled={disabled}
          classNames={classNames}
          clearPadding={clearPadding}
          variableTextClassName={variableTextClassName}
          onCaretMoved={onCaretMoved}
        />
        {!isValid && (
          <span style={{ color: 'var(--color-redFull)', fontSize: '0.9em' }}>
            {errorText}
          </span>
        )}
        <DropdownContainer
          variables={variables}
          matchingText={matchingText}
          onSelectVariable={onSelectVariable}
          onHide={this.hideVariables}
        />
      </div>
    );
  }
}
