import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import {
  engagementBoostOff,
  hasAudience,
  hasRetargettingNotif,
  hasTopic,
} from 'models/publisher/settings';
import { useCallback } from 'react';
import { useHasChanged } from './useChanged';

export const useSettingsChanges = (): {
  topicOrAudienceChanged: () => boolean;
  retargettingNotifOrEngagementBoostChanged: () => boolean;
} => {
  const { settings } = useSettings();

  const withAudience = hasAudience(settings);
  const withTopic = hasTopic(settings);
  const withRetargettingNotif = hasRetargettingNotif(settings);
  const hasEngagementBoostOff = engagementBoostOff(settings);

  const audiencesChanged = useHasChanged(withAudience);
  const topicsChanged = useHasChanged(withTopic);

  const retargettingNotifsChanged = useHasChanged(withRetargettingNotif);
  const engagementBoostOffChanged = useHasChanged(hasEngagementBoostOff);

  const topicOrAudienceChanged = useCallback(() => {
    return topicsChanged || audiencesChanged;
  }, [topicsChanged, audiencesChanged]);

  const retargettingNotifOrEngagementBoostChanged = useCallback(() => {
    return retargettingNotifsChanged || engagementBoostOffChanged;
  }, [retargettingNotifsChanged, engagementBoostOffChanged]);

  return { topicOrAudienceChanged, retargettingNotifOrEngagementBoostChanged };
};
