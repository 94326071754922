import React from 'react';
import { IColumn, UserActivityStreamCard } from '../UserActivityStreamCard';

export const EmailUserActivityCard: React.FC<{
  campaignId: number;
}> = ({ campaignId }) => {
  const formatDate = (date?: string) => {
    return date && date !== null
      ? new Date(date).toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })
      : '';
  };
  const formatSubscriptionStatus = (date?: string) => {
    return date && date !== null
      ? `Unsubscribed\n${new Date(date).toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })}`
      : 'Subscribed\n\n';
  };

  const columns: IColumn[] = [
    { key: 'user_name', header: 'Name', width: '13%' },
    { key: 'email', header: 'Email', width: '13%' },
    {
      key: 'last_email_delivered',
      header: 'Last Delivered',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_email_opened',
      header: 'Last Opened',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_email_clicked',
      header: 'Last Clicked',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_subscription_status_event',
      header: 'Subscription Status',
      width: '15%',
      formatCell: formatSubscriptionStatus,
    },
    {
      key: 'last_error',
      header: 'Last Error',
      width: '15%',
      formatCell: formatDate,
    },
  ];

  return (
    <UserActivityStreamCard
      campaignId={campaignId}
      columns={columns}
      channel="Email"
    />
  );
};
