export function compactArray<T>(array: Array<T | null>): Array<T> {
  return array.filter((item) => item !== null) as Array<T>;
}

export function isEmptyArray<T>(arr?: T[]): boolean {
  return !(arr && arr?.length > 0);
}

export function isEmpty(
  value: Record<string, unknown> | string | unknown
): boolean {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' &&
      Object.keys(value as Record<string, unknown>).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export function groupArrayBy<T, K extends string | number>(
  array: readonly T[],
  groupBy: (item: T) => K
): Record<K, T[]> {
  return array.reduce((grouped, item) => {
    const key = groupBy(item);
    return {
      ...grouped,
      [key]: [...(grouped[key] ?? []), item],
    };
  }, {} as Record<K, T[]>);
}

/**
 * `Array.prototype.includes` assumes you're using the string to check
 * something about the array, but we're using it to check if the string
 * is in the array. This is a type-safe version of that.
 */
export function includes<S extends string>(
  haystack: readonly S[],
  needle: string
): needle is S {
  const stack: readonly string[] = haystack;
  return stack.includes(needle);
}

/**
 * Takes a value and a function and if the value is not undefined, it
 * applies the function to the value and returns the result. Otherwise,
 * it returns undefined.
 */
export const maybe = <F, V>(
  value: V | undefined,
  fn: (arg: V) => F
): F | undefined => {
  if (value) {
    return fn(value);
  }
  return undefined;
};
