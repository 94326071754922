import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { PublisherProvider } from 'contexts/publisher';
import { useProgram } from 'contexts/program';
import { ContentResultsPage } from './ContentResultsPage';

export const ContentResults: React.FC<RouteComponentProps<{
  id?: string;
}>> = ({ id }) => {
  const program = useProgram();
  return (
    <PublisherProvider programId={program.id} id={Number(id)}>
      <ContentResultsPage />
    </PublisherProvider>
  );
};
