import React from 'react';
import { DateTime } from 'luxon';
import { UsersDeliveredCard } from './MetricsCards/Email/UsersDeliveredCard';
import { EmailOpenRateCard } from './MetricsCards/Email/EmailOpenRateCard';
import { Post } from '../../../../../models/publisher/post';
import { useCampaignResults } from '../../../../../hooks/campaign-results';
import styles from './MetricsCards/MetricsCards.module.css';
import { EmailClickThroughRateCard } from './MetricsCards/Email/EmailClickThroughRateCard';
import { EmailErrorRateCard } from './MetricsCards/Email/EmailErrorRateCard';
import { PerformanceTrendCard } from './MetricsCards/Omni/PerformanceTrendCard';
import { EmailClickEngagementCard } from './MetricsCards/Email/EmailClickEngagementCard';
import { ContentOpenTimeCard } from './MetricsCards/Omni/ContentOpenTimeCard';
import { UsersTargetedCard } from './MetricsCards/Omni/UsersTargetedCard';
import { UsersEngagedCard } from './MetricsCards/Omni/UsersEngagedCard';
import { UserEngagementFunnelCard } from './MetricsCards/Omni/UserEngagementFunnelCard';
import { ContentSourceCard } from './MetricsCards/Omni/ContentSourceCard';
import { ContentEngagementActionCard } from './MetricsCards/Omni/ContentEngagementActionCard';
import { CommentSentimentCard } from './MetricsCards/Omni/CommentSentimentCard';
import { DeliveryChannelPerformanceCard } from './MetricsCards/Omni/DeliveryChannelPerformanceCard';
import { EmailOpenTimeCard } from './MetricsCards/Email/EmailOpenTimeCard';
import { EmailUserActivityCard } from './MetricsCards/Email/EmailUserActivityCard';
import { OmniUserActivityCard } from './MetricsCards/Omni/OmniUserActivityCard';

interface IMetricsV2Section {
  post: Post;
  isEmailOnly: boolean;
}

export const MetricsV2Section: React.FC<IMetricsV2Section> = ({
  post,
  isEmailOnly = false,
}) => {
  const { content } = post;
  const startDate =
    (post.content.publishedAt && DateTime.fromISO(post.content.publishedAt)) ||
    DateTime.now();
  const { results, isLoading } = useCampaignResults(content);

  return isEmailOnly ? (
    <div className={styles.metricsPanel}>
      <>
        <UsersDeliveredCard data={results} isLoading={isLoading} />
        <EmailOpenRateCard data={results} isLoading={isLoading} />
        <EmailClickThroughRateCard data={results} isLoading={isLoading} />
        <EmailErrorRateCard data={results} isLoading={isLoading} />
        <PerformanceTrendCard
          data={results}
          startDate={startDate}
          isLoading={isLoading}
          keyFilter={['Email Opens', 'Email Clicks']}
        />
        <EmailClickEngagementCard data={results} isLoading={isLoading} />
        <EmailOpenTimeCard data={results} isLoading={isLoading} />
        <EmailUserActivityCard campaignId={post.content.id} />
      </>
    </div>
  ) : (
    <div className={styles.metricsPanel}>
      <>
        <UsersTargetedCard data={results} isLoading={isLoading} />
        <UsersEngagedCard data={results} isLoading={isLoading} />
        <UserEngagementFunnelCard data={results} isLoading={isLoading} />
        <PerformanceTrendCard
          data={results}
          startDate={startDate}
          isLoading={isLoading}
          keyFilter={['Reached Users', 'Opened Users', 'Engaged Users']}
        />
        <ContentSourceCard data={results} isLoading={isLoading} />
        <ContentEngagementActionCard data={results} isLoading={isLoading} />
        <CommentSentimentCard post={post} />
        <ContentOpenTimeCard data={results} isLoading={isLoading} />
        <DeliveryChannelPerformanceCard data={results} isLoading={isLoading} />
        <OmniUserActivityCard campaignId={post.content.id} />
      </>
    </div>
  );
};
