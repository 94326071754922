import { RouteComponentProps, useNavigate } from '@reach/router';
import React, { useCallback, useState } from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { FormPage } from 'DesignSystem/Layout/Pages';
import {
  useBulkCreateBoxFolderAudienceMapping,
  useCreateBoxFolder,
} from 'hooks/box-knowledge-mangement';
import { Audience } from 'models/audience';
import { BoxIntegrationData } from 'models/box-integration';
import { BoxFolderForm, BoxFolderFormContext } from '../shared/Form/Folder';

export const NewBoxFolder: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const {
    createAsync: createBoxFolder,
    isLoading: isCreatingBoxFolder,
  } = useCreateBoxFolder();
  const {
    bulkCreateAsync: bulkCreateFolderAudienceMapping,
    isLoading: isCreatingFolderAudienceMapping,
  } = useBulkCreateBoxFolderAudienceMapping();

  const { setFlashMessage } = useFlashMessage();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [boxFolder, setBoxFolder] = useState<BoxIntegrationData>();

  const onSave = useCallback(async () => {
    if (!name || !description || !audiences.length || !boxFolder) {
      return;
    }

    try {
      const folder = await createBoxFolder({
        programId,
        name,
        description,
        boxFolderId: boxFolder.resource_id,
      });

      await bulkCreateFolderAudienceMapping({
        programId,
        folderId: folder.id,
        audienceIds: audiences
          .filter(
            (audience): audience is Audience & Required<Pick<Audience, 'id'>> =>
              !!audience.id
          )
          .map((audience) => audience.id),
      });
    } catch (err) {
      setFlashMessage({
        severity: 'error',
        message: 'There was an error creating the folder. Please try again.',
      });
      throw err;
    }

    await navigate('..');
  }, [
    audiences,
    boxFolder,
    bulkCreateFolderAudienceMapping,
    createBoxFolder,
    description,
    name,
    navigate,
    programId,
    setFlashMessage,
  ]);

  return (
    <FormPage
      breadcrumbs={[
        { to: '../../..', label: 'Configure' },
        { to: '../..', label: 'Box' },
        { label: 'Create Folder' },
      ]}
      actions={[
        {
          label: 'Save',
          disabled: !name || !description || !audiences.length || !boxFolder,
          isLoading: isCreatingBoxFolder || isCreatingFolderAudienceMapping,
          onClick: onSave,
        },
      ]}
      title="Create Folder"
    >
      <BoxFolderFormContext.Provider
        value={{
          name,
          description,
          audiences,
          boxFolder,
          onNameChange: setName,
          onDescriptionChange: setDescription,
          onAudiencesChange: setAudiences,
          onBoxFolderChange: setBoxFolder,
        }}
      >
        <BoxFolderForm />
      </BoxFolderFormContext.Provider>
    </FormPage>
  );
};
