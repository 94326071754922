import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { request } from './api-shared';
import { Page } from './common';

export type FetchProps = {
  programId: number;
  page?: number;
  pageSize?: number;
};

export type BoxFolderData = {
  id: string;
  name: string;
  description: string;
  boxFolderId: string;
  audiences: {
    folderMappingId: string;
    audienceId: string;
    createdAt: string;
    updatedAt: string;
  }[];
  programId: number;
  folderCreatorId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
};

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export async function fetchBoxFoldersPage({
  programId,
}: FetchProps): Promise<Page<BoxFolderData>> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/folders`;
  const response = await request(url);

  if (response.status === 200) {
    return response
      .json()
      .then((body) => {
        const result = camelcaseKeys(body, { deep: true });
        return { data: result };
      })
      .then(({ data, ...rest }) => {
        return {
          data,
          meta: {
            totalRecords: data.length,
            totalPages: 1,
            currentPage: 1,
            pageSize: data.length,
          },
          ...rest,
        };
      });
  }

  throw new Error(`Error fetching folders: ${response.status}`);
}

export type CreateBoxFolderData = Pick<
  BoxFolderData,
  'programId' | 'name' | 'description' | 'boxFolderId'
>;

export async function createBoxFolder(
  data: CreateBoxFolderData
): Promise<BoxFolderData> {
  const url = `${apiRoot}/v2/tenants/program:${data.programId}/box_integration/folders`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakeCaseKeys(data)),
  });

  if (response.status % 200 < 100) {
    return response.json().then((output) => camelcaseKeys(output));
  }

  throw new Error(`Error creating folder: ${response.status}`);
}

export async function createBoxFolderAudienceMapping(
  programId: number,
  folderId: string,
  audienceId: string
): Promise<void> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/folders/${folderId}/audiences/${audienceId}`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status % 200 >= 100) {
    throw new Error(
      `Error creating folder-audience mapping: ${response.status}`
    );
  }
}

// export const deleteBoxMangementFolder = async (
//   programId: number,
//   userId: number,
//   folderId: number
// ): Promise<BoxFolderData> => {
//     // const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/users/${userId}/folders/${folderId}`;
//   // const response = await request(url, {
//   //   method: 'DELETE',
//   // });
//   // if (response.status === 200) {
//   //   return response.json().then((output) => deepCamelcaseKeys(output));
//   // }
//   // throw new Error(`Error archive author alias: ${response.status}`);
//   console.log(programId, userId, folderId)
//   return Promise.resolve({
//     // create fake BoxFolderData here, at least 5 of them
//            id: "123",
//            name: "test folder",
//            description: "Test description",
//            boxFolderId: "12",
//            audiences: [
//              {
//                  folderMappingId: "1",
//                  audienceId: "12",
//                  createdAt: "2024-07-01T10:24:52.617Z",
//                  updatedAt: "2024-07-01T10:24:52.617Z"
//              }
//            ],
//            programId: 42,
//            folderCreatorId: "32",
//            createdAt: "2024-06-19T04:05:06.000Z",
//            updatedAt: "2024-06-19T04:05:06.000Z"

//  })
// };
