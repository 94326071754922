import React from 'react';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { EmailAlias } from 'models/email-alias';
import { Field, Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { TextInput } from 'shared/TextInput';
import { Textarea } from 'shared/forms/Textarea';
import { FlipSwitch } from 'shared/FlipSwitch';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { TopicsSection } from './TopicsSection';

import styles from './form.module.css';

type PropsType = {
  emailAlias: Partial<EmailAlias>;
  onChange: (data: Partial<EmailAlias>) => void;
};

export const Form: React.FC<PropsType> = ({ emailAlias, onChange }) => {
  const { id: programId } = useProgram();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );

  return (
    <div className={styles.form}>
      <Section title="Labeling">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Name">
              <TextInput
                onChange={(name) => onChange({ name })}
                value={emailAlias.name}
                className={styles.textField}
              />
            </Field>
            <Field label="Description">
              <Textarea
                onChange={(description) => onChange({ description })}
                value={emailAlias.description}
                className={styles.textField}
              />
            </Field>
          </div>
        </Fieldset>
      </Section>

      <Section title="General">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="From name">
              <TextInput
                onChange={(senderName) => onChange({ senderName })}
                value={emailAlias.senderName}
                className={styles.textField}
              />
            </Field>
            <Field label="From email">
              <TextInput
                onChange={(senderEmail) => onChange({ senderEmail })}
                value={emailAlias.senderEmail}
                className={styles.textField}
              />
            </Field>
            <Field label="Reply-to email">
              <TextInput
                onChange={(replyToEmail) => onChange({ replyToEmail })}
                value={emailAlias.replyToEmail}
                className={styles.textField}
              />
            </Field>
          </div>
        </Fieldset>
      </Section>

      <Section title="On behalf of">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="On behalf sender">
              <div className="kai-flex-row">
                <div className={styles.marginRight}>
                  <FlipSwitch
                    size="xl"
                    shape="rounded"
                    on={emailAlias.onBehalfOfSenderEnabled === true}
                    onChange={(value) =>
                      value
                        ? onChange({ onBehalfOfSenderEnabled: true })
                        : onChange({ onBehalfOfSenderEnabled: false })
                    }
                  />
                </div>
                Display &apos;on behalf of&apos; for this address
              </div>
            </Field>
            {emailAlias.onBehalfOfSenderEnabled && (
              <>
                <Field label="Sender name">
                  <TextInput
                    onChange={(onBehalfName) => onChange({ onBehalfName })}
                    value={emailAlias.onBehalfName}
                    className={styles.textField}
                  />
                </Field>
                <Field label="Sender email">
                  <TextInput
                    onChange={(onBehalfEmail) => onChange({ onBehalfEmail })}
                    value={emailAlias.onBehalfEmail}
                    className={styles.textField}
                  />
                </Field>
              </>
            )}
          </div>
        </Fieldset>
      </Section>
      <Section title="Physical address">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Physical address">
              <div className="kai-flex-row">
                <div className={styles.marginRight}>
                  <FlipSwitch
                    size="xl"
                    shape="rounded"
                    on={emailAlias.customPhysicalAddressEnabled === true}
                    onChange={(value) =>
                      value
                        ? onChange({ customPhysicalAddressEnabled: true })
                        : onChange({ customPhysicalAddressEnabled: false })
                    }
                  />
                </div>
                Use custom physical address
              </div>
            </Field>
          </div>
        </Fieldset>
        {emailAlias.customPhysicalAddressEnabled && (
          <>
            <Fieldset>
              <div className={fieldsetStyles.fieldGroupContainer}>
                <Field label="Company name">
                  <TextInput
                    onChange={(companyName) => onChange({ companyName })}
                    value={emailAlias.companyName}
                    className={styles.textField}
                  />
                </Field>
              </div>
            </Fieldset>

            <Fieldset>
              <div className={fieldsetStyles.fieldGroupContainer}>
                <Field label="Address">
                  <div className={styles.addressFields}>
                    <TextInput
                      onChange={(addressOne) => onChange({ addressOne })}
                      value={emailAlias.addressOne}
                      className={styles.textField}
                      placeholder="Address line 1"
                    />
                    <TextInput
                      onChange={(addressTwo) => onChange({ addressTwo })}
                      value={emailAlias.addressTwo}
                      className={styles.textField}
                      placeholder="Address line 2"
                    />
                    <TextInput
                      onChange={(city) => onChange({ city })}
                      value={emailAlias.city}
                      className={styles.textField}
                      placeholder="City"
                    />
                    <div className={styles.stateZipInputs}>
                      <TextInput
                        onChange={(state) => onChange({ state })}
                        value={emailAlias.state}
                        placeholder="State / Providence"
                      />
                      <TextInput
                        onChange={(zip) => onChange({ zip })}
                        value={emailAlias.zip}
                        placeholder="Zip / Postal code"
                      />
                    </div>
                  </div>
                </Field>
              </div>
            </Fieldset>
          </>
        )}
      </Section>
      {!showPermissionsUI?.value && (
        <TopicsSection emailAlias={emailAlias} onChange={onChange} />
      )}
    </div>
  );
};
