import React from 'react';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import { CommunicationStep } from 'models/journeys/journey';
import { useProgram } from 'contexts/program';
import {
  PREVIEW_TABS,
  PreviewTab,
  PreviewType,
  usePreview,
} from 'contexts/publisher/compose/preview';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Switcher } from 'components/publisher/previews/Switcher';
import { Box } from 'DesignSystem/Components';
import compose from 'components/publisher/theme/compose.module.css';
import cx from 'classnames';
import { useFlashMessage } from 'contexts/flasher';
import { useDesign, useDesignQuery } from 'hooks/design';
import { defaultPost } from 'models/publisher/post';
import { SendTestCampaignButton } from 'App/Program/Editors/Publisher/NewReview/ContentPreviewSection/SendTestCampaign';
import { DesignContext, useDesignContext } from 'contexts/design';
import { Targets } from 'models/donkey';
import { Flex } from 'DesignSystem/Layout/Flex';
import { VariablePreviewOptionsComponent } from 'components/publisher/pages/Preview/VariablePreviewOptions';
import styles from './CommunicationPreview.module.css';
import { useJourneyContentDesigner } from '../../JourneyContentDesigner/JourneyContentDesignProvider';
import { SendTestJourneyContentModal } from './SendTestJourneyContentModal';

export const SendTestJourneyContent: React.FC = () => {
  const {
    design: { blocks },
    status,
    isProcessing,
  } = useDesignContext();

  // get delivery channels here when the journey omni-channel feature is implemented
  const [showTestCampaignModal, setShowTestCampaignModal] = React.useState(
    false
  );
  const visibleBlocksForEmail = blocks.filter(
    (block) =>
      block?.target === undefined ||
      !block?.target.excluded.includes(Targets.email)
  ).length;

  if (visibleBlocksForEmail < 1) {
    return null;
  }
  const toggleShow = () => setShowTestCampaignModal(!showTestCampaignModal);
  return (
    <>
      <SendTestCampaignButton
        disabled={blocks.length === 0 || isProcessing || status.isSaving}
        processing={isProcessing}
        label="Send a Test Communication"
        onClick={toggleShow}
      />

      {showTestCampaignModal && (
        <SendTestJourneyContentModal toggleShow={toggleShow} />
      )}
    </>
  );
};

export const CommunicationPreview: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();

  const { inlineEditingEnabled } = useJourneyContentDesigner();

  const { data, isLoading: isDesignLoading } = useDesignQuery({
    programId,
    id: step.designId ?? 'new',
  });
  const designContext = useDesign(programId, step.designId ?? 'new', false);

  const {
    tabs,
    tab,
    setTab,
    html,
    isLoading: isPreviewLoading,
    errors,
    webFontsEnabled,
    toggleWebFonts,
    previewAs,
    setPreviewAs,
  } = usePreview(
    {
      blocks: data?.blocks ?? [],
      styles: data?.styles ?? defaultPost.styles,
      settings: defaultPost.settings,
    },
    [PreviewType.microapp]
  );

  React.useEffect(() => {
    setTab(PREVIEW_TABS.web);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iframe = React.useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = React.useState(2000);

  const onLoad = () => {
    const scrollHeight = iframe.current?.contentDocument?.body?.scrollHeight;
    if (scrollHeight) setHeight(scrollHeight + 10);
  };

  React.useEffect(() => {
    if (!errors.length) return;

    setFlashMessage({
      severity: 'error',
      message: errors.map((e) => e.message).join('\n'),
    });
  }, [errors, setFlashMessage]);

  const handleSetTab = (newTab: PreviewTab) => {
    if ((newTab.name === PreviewType.email) === webFontsEnabled) {
      toggleWebFonts();
    }
    setTab(newTab);
  };

  return (
    <DesignContext.Provider value={designContext}>
      <FlexLayout.Flex spread>
        {inlineEditingEnabled && (
          <Flex column start className={styles.previewOptions}>
            <VariablePreviewOptionsComponent
              blocks={designContext.design.blocks}
              previewAs={previewAs}
              setPreviewAs={setPreviewAs}
            />
            <SendTestJourneyContent />
          </Flex>
        )}
        <Box className={cx(styles.previewContainer, compose.main)}>
          {tab && (
            <tab.frame>
              {isPreviewLoading ? (
                <div
                  className="flex-centered kai-flex-row"
                  style={{
                    margin: '100px auto',
                  }}
                >
                  <LoadingSpinner />
                </div>
              ) : (
                <iframe
                  title="Preview"
                  srcDoc={html}
                  onLoad={onLoad}
                  ref={iframe}
                  style={{
                    width: '100%',
                    height: `${height}px`,
                    minHeight: '100%',
                  }}
                />
              )}
            </tab.frame>
          )}
          {!isDesignLoading && (
            <Switcher
              tab={tab}
              tabs={tabs}
              setTab={handleSetTab}
              className={styles.switcher}
            />
          )}
        </Box>
      </FlexLayout.Flex>
    </DesignContext.Provider>
  );
};
