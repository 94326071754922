import React from 'react';
import { Box } from 'DesignSystem/Components';
import { ScheduleInsightByChannel } from 'models/publisher/orchestration-insights';
import { DateTime } from 'luxon';
import { getNumberSuffix } from 'utility/text';
import { SectionCard } from '../../SectionCard';
import styles from './daily-stats.module.css';

export const DailyStats: React.FC<{
  insights: ScheduleInsightByChannel[];
  publishedAt: string | undefined;
}> = ({ insights, publishedAt }) => {
  const descriptorMap: { [key: string]: JSX.Element } = {
    'the post in the selected topics or the For You feed': (
      <>
        have access to this campaign in the <strong>selected topics</strong> or
        the <strong>For You feed</strong>
      </>
    ),
    'an Email': (
      <>
        be sent an <strong>email</strong>
      </>
    ),
    'an Assistant notification': (
      <>
        be sent a notification through <strong> Assistant</strong>
      </>
    ),
    'a Push notification': (
      <>
        be sent a <strong>push notification</strong>
      </>
    ),
  };

  const formattedDate = (label: string) => {
    const [, daysCount] = label.split(' ');
    const publishDate = publishedAt
      ? DateTime.fromJSDate(new Date(publishedAt))
      : DateTime.now();
    const date = publishDate.plus({ day: parseInt(daysCount, 10) - 1 });

    return date.toFormat(`cccc LLLL d'${getNumberSuffix(date.day)}' kkkk`);
  };

  return (
    <div className={styles.StatsSection}>
      {insights.map((insight) => (
        <div key={insight.label} className={styles.SectionCard}>
          <SectionCard padding={[16, 24]}>
            <Box className={styles.StatsLine}>
              <div className={styles.DateContainer}>
                <strong>{insight.label}</strong> •{' '}
                {formattedDate(insight.label)}
              </div>

              <Box className={styles.PercentageStats}>
                {insight.items.map((item) => (
                  <div key={item.channelDescriptor} className={styles.StatLine}>
                    <strong>{item.percentage}%</strong> of users will{' '}
                    {descriptorMap[item.channelDescriptor]}
                  </div>
                ))}
              </Box>
            </Box>
          </SectionCard>
        </div>
      ))}
      {/* We may resurrect it later */}
      {/* <Box style={{ display: 'flex' }}>
        <Box className={styles.BreakdownLink}>View Full Breakdown</Box>
      </Box> */}
    </div>
  );
};
