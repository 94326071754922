import React, { useState } from 'react';
import Select from 'react-select';
import { useProgram } from 'contexts/program';
import { PreviewContext } from 'contexts/publisher/compose/preview';
import { Audience } from 'models/audience';
import { User } from 'models/user';
import { userToName } from 'App/Program/Main/People/mappings';
import { useUsersInfiniteQuery } from 'hooks/user';
import { useAudiencesInfiniteQuery } from 'hooks/audience';
import { useDebounce } from 'hooks/useDebounce';
import { DefaultAvatar } from 'shared/icons';
import styles from './personalized-field-select.module.css';

export type PreviewUser = User & { label: string; value: string };
export type PreviewAudience = Audience & { label: string; value: string };

export const NewVariablePreviewOptions: React.FC = () => {
  const { id: programId } = useProgram();
  const [search, setSearch] = useState('');
  const [audienceSearch, setAudienceSearch] = useState('');

  const [viewAs, setViewAs] = useState({
    id: 'anonymous',
    accessibleBrands: [],
    accessiblePrograms: [],
    label: 'Default Values',
    value: 'Default Values',
    avatarUrl: null,
  });

  const [selectedAudience, setSelectedAudience] = useState({
    id: 'all',
    accessibleBrands: [],
    accessiblePrograms: [],
    label: 'All Audiences',
    value: 'All Audiences',
  });

  const [selectedUser, setSelectedUser] = useState(null);

  const { isLoading, data, fetchNextPage, hasNextPage } = useUsersInfiniteQuery(
    {
      programId,
      filters: { withoutHiddenOnProgram: true },
      search: useDebounce(search),
      audiences:
        selectedAudience.id === 'all' ? undefined : [selectedAudience.id],
    }
  );

  const {
    isLoading: isLoadingAudiences,
    data: audienceData,
    fetchNextPage: fetchNextPageAudiences,
    hasNextPage: hasNextPageAudiences,
  } = useAudiencesInfiniteQuery({
    programId,
    search: useDebounce(audienceSearch),
    statuses: ['enabled'],
  });

  function mapUserToPreview(user: User): PreviewUser {
    return {
      id: user.id,
      accessibleBrands: user.accessibleBrands,
      accessiblePrograms: user.accessiblePrograms,
      label: userToName(user),
      value: userToName(user),
      avatarUrl: user.avatarUrl,
    };
  }

  function mapAudienceToPreview(audience: Audience): PreviewAudience {
    return {
      ...audience,
      id: audience.id,
      label: audience.title,
      value: audience.title,
      totalUsers: audience.totalUsers,
      query: audience.query,
    };
  }

  const viewAsOptions = [
    {
      id: 'anonymous',
      accessibleBrands: [],
      accessiblePrograms: [],
      label: 'Default Values',
      value: 'Default Values',
    },
    {
      id: 'self',
      accessibleBrands: [],
      accessiblePrograms: [],
      label: 'Self',
      value: 'Self',
    },
    {
      id: 'audience',
      accessibleBrands: [],
      accessiblePrograms: [],
      label: 'Audience Member',
      value: 'Audience Member',
    },
  ];

  const users = React.useMemo(() => {
    const options: {
      id: string;
      accessibleBrands: string[];
      accessiblePrograms: string[];
      label: string;
      value: string;
      avatarUrl: string | undefined;
    }[] = [];
    if (selectedAudience && data)
      return [...options, ...data.map((u: User) => mapUserToPreview(u))];

    return options;
  }, [selectedAudience, data]);

  const audiences = React.useMemo(() => {
    const options: {
      id: string;
      accessibleBrands: string[];
      accessiblePrograms: string[];
      label: string;
      value: string;
    }[] = [
      {
        id: 'all',
        accessibleBrands: [],
        accessiblePrograms: [],
        label: 'All Audiences',
        value: 'All Audiences',
      },
    ];
    if (audienceData)
      return [
        ...options,
        ...audienceData.map((a: Audience) => mapAudienceToPreview(a)),
      ];

    return options;
  }, [audienceData]);

  const { setPreviewAs } = React.useContext(PreviewContext);

  React.useEffect(() => {
    setPreviewAs('anonymous');
  }, [setPreviewAs]);

  const setPreviewViewAs = React.useCallback((type) => {
    setViewAs(type);
    setSelectedAudience({
      id: 'all',
      accessibleBrands: [],
      accessiblePrograms: [],
      label: 'All Audiences',
      value: 'All Audiences',
    });
    setSelectedUser(null);
  }, []);

  const setPreviewAudience = React.useCallback(
    (audience) => {
      setSelectedAudience(audience);
      setPreviewAs('anonymous');
      setSelectedUser(null);
    },
    [setPreviewAs]
  );

  const setPreviewUser = React.useCallback((user) => {
    setSelectedUser(user);
  }, []);

  const onScroll = React.useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage]);

  const onScrollAudiences = React.useCallback(() => {
    if (hasNextPageAudiences) fetchNextPageAudiences();
  }, [fetchNextPageAudiences, hasNextPageAudiences]);

  const onViewAsChange = React.useCallback(
    (value) => {
      setPreviewViewAs(value);
      if (value.id !== 'audience') {
        setPreviewAs(value.id);
      }
    },
    [setPreviewViewAs, setPreviewAs]
  );

  const onAudienceChange = React.useCallback(
    (value) => {
      setPreviewAudience(value);
    },
    [setPreviewAudience]
  );

  const onSelectChange = React.useCallback(
    (value) => {
      setPreviewUser(value);
      setPreviewAs(value.id);
    },
    [setPreviewAs, setPreviewUser]
  );

  return (
    <div className={styles.variableSelect}>
      <div className={styles.userLabel}>View As</div>
      <Select
        onMenuScrollToBottom={onScroll}
        isLoading={isLoading}
        onChange={onViewAsChange}
        onInputChange={(e) => setSearch(e)}
        value={viewAs}
        options={viewAsOptions}
        placeholder="Click to search"
      />
      {viewAs.id === 'audience' && (
        <>
          <div className={styles.userLabel}> Audience </div>
          <Select
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatOptionLabel={(d: any, { context }) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span className={styles.optionLabel}>{d.label}</span>
                {context === 'menu' &&
                (d.totalUsers === 0 ||
                  d.totalUsers ||
                  d.label === 'All Audiences') ? (
                  <span className={styles.userCount}>
                    {d.label === 'All Audiences' ? '' : `${d.totalUsers} users`}
                  </span>
                ) : (
                  ''
                )}
              </div>
            )}
            onMenuScrollToBottom={onScrollAudiences}
            isLoading={isLoadingAudiences}
            onChange={onAudienceChange}
            onInputChange={(e) => setAudienceSearch(e)}
            value={selectedAudience}
            options={audiences}
            placeholder="Click to search"
          />

          <div className={styles.userLabel}> User </div>
          <Select
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatOptionLabel={(d: any, { context }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {context === 'menu' && d.avatarUrl && (
                  <img className={styles.userAvatar} src={d.avatarUrl} alt="" />
                )}
                {context === 'menu' && !d.avatarUrl && <DefaultAvatar />}
                <span
                  className={
                    context !== 'menu'
                      ? styles.optionLabel
                      : styles.userOptionLabel
                  }
                >
                  {d.label}
                </span>
              </div>
            )}
            onMenuScrollToBottom={onScroll}
            isLoading={isLoading}
            onChange={onSelectChange}
            onInputChange={(e) => setSearch(e)}
            value={selectedUser}
            options={users}
            placeholder="Click to search"
            noOptionsMessage={() => 'No Members'}
          />
        </>
      )}
    </div>
  );
};
