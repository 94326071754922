import React from 'react';
import { arc, pie } from 'd3';
import { ChartDimensions } from './Util';
import { NoDataChart } from './NoDataChart';

interface IProgressDonut {
  percentage?: number;
  chartDimensions?: ProgressDonutChartDimensions;
}

type PieChartData = {
  label: string;
  value: number;
};

type ProgressDonutChartDimensions = ChartDimensions & {
  radiusRatio: number;
  innerFontSize: number;
};

export const ProgressDonut: React.FC<IProgressDonut> = ({
  percentage,
  chartDimensions = {
    height: 300,
    width: 300,
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    radiusRatio: 0.8,
    innerFontSize: 20,
  },
}) => {
  const { height, width, radiusRatio: ratio, innerFontSize } = chartDimensions;
  const data = [
    { label: 'total', value: percentage || 0 },
    { label: 'remainder', value: 1 - (percentage || 0) },
  ];
  const padding = {
    left: 5,
    right: 5,
    top: 5,
    bottom: 5,
  };

  const radius =
    Math.min(
      width - padding.left - padding.right,
      height - padding.top - padding.bottom
    ) / 2;

  const drawPie = pie<PieChartData>()
    .value((d: { label: string; value: number }) => d.value)
    .sort(null);
  const drawArc = arc();

  const color = ['url(#greenGradient)', 'rgba(232, 231, 231, 1)'];

  const renderPie = (_data: Array<{ label: string; value: number }>) => {
    return (
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        {drawPie(_data).map(
          (
            d: { startAngle: number; endAngle: number; value: number },
            i: number
          ) => {
            const sliceInfo = {
              innerRadius: radius * ratio,
              outerRadius: radius,
              startAngle: d.startAngle,
              endAngle: d.endAngle,
            };
            return (
              <path
                key={`arc-d-${d.value}`}
                d={drawArc(sliceInfo) ?? ''}
                fill={color[i]}
              />
            );
          }
        )}
      </g>
    );
  };

  return percentage === undefined ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <linearGradient id="greenGradient" x1="0%" x2="100%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="rgba(126, 202, 4, 1)" />
          <stop offset="100%" stopColor="rgba(160, 231, 45, 1)" />
        </linearGradient>
      </defs>
      {renderPie(data)}
      <text
        x={width * 0.5}
        y={height * 0.5}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={innerFontSize}
      >
        {(percentage * 100).toFixed(0)}%
      </text>
    </svg>
  );
};
