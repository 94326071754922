import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useEmailAliasQuery } from 'hooks/email-alias';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { EmailAlias } from 'models/email-alias';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { useActions } from './useActions';
import { Sidebar } from './components/Sidebar';

import styles from './email-alias.module.css';

export const EditEmailAlias: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const [emailAlias, setEmailAlias] = React.useState<EmailAlias>();
  const { update, archive, unarchive } = useActions(setEmailAlias);

  const { data, isLoading, errorMessage } = useEmailAliasQuery(
    programId,
    id as string
  );

  function onChange(updatedData: Partial<EmailAlias>) {
    setEmailAlias({ ...(emailAlias as EmailAlias), ...updatedData });
  }

  React.useEffect(() => {
    if (data) setEmailAlias(data);
    // eslint-disable-next-line
  }, [isLoading]);

  const header = (
    <>
      <h1 className="page-header">Email Alias</h1>
    </>
  );

  const main = (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {errorMessage && <>{errorMessage}</>}
      {!isLoading && !errorMessage && (
        <Form emailAlias={emailAlias as EmailAlias} onChange={onChange} />
      )}
    </>
  );

  function onSave() {
    update(emailAlias as EmailAlias);
  }

  return (
    <WithPermission permissions={['confContentAccess']}>
      <Helmet>
        <title>Edit Email Alias</title>
      </Helmet>
      {emailAlias && (
        <MainEditor
          header={header}
          main={main}
          sidebar={
            <Sidebar
              emailAlias={emailAlias}
              archive={archive}
              unarchive={unarchive}
            />
          }
          footer={<Footer emailAlias={emailAlias} action={onSave} />}
        />
      )}
    </WithPermission>
  );
};
