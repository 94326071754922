import React, { useEffect, useMemo, useRef } from 'react';
import {
  axisBottom,
  axisLeft,
  AxisScale,
  interpolateSpectral,
  quantize,
  scaleBand,
  scaleLinear,
  ScaleOrdinal,
  scaleOrdinal,
  select,
} from 'd3';
import { ChartDimensions } from './Util';
import { NoDataChart } from './NoDataChart';

export type BarData = { label: string; value: number };
export type BarChartDimensions = ChartDimensions & {
  bars: {
    padding: {
      inner: number;
      outer: number;
    };
  };
};

export type CustomDrawingFunction = (opts: {
  bars?: Array<BarData>;
  chartDimensions?: BarChartDimensions;
  scaleX?: AxisScale<string> | AxisScale<number>;
  scaleY?: AxisScale<string> | AxisScale<number>;
  colorScale?: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>;
}) => React.ReactFragment;

interface IHorizontalBarChartBarChart {
  hideXAxis?: boolean;
  hideYAxis?: boolean;
  data: Array<BarData>;
  customColors?: ScaleOrdinal<string, unknown>;
  customDrawing?: CustomDrawingFunction;
  chartDimensions?: BarChartDimensions;
  units?: string;
}

export const HorizontalBarChart: React.FC<IHorizontalBarChartBarChart> = ({
  hideXAxis = false,
  hideYAxis = false,
  data,
  units,
  customColors,
  customDrawing,
  chartDimensions = {
    height: 480,
    width: 640,
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    bars: {
      padding: {
        inner: 0,
        outer: 0,
      },
    },
  },
}) => {
  const {
    padding,
    width,
    height,
    bars: { padding: barPadding },
  } = chartDimensions;

  const domain = useMemo(() => [0, Math.max(...data.map((d) => d.value), 1)], [
    data,
  ]);

  const xAxis = useRef<SVGGElement>(null);
  const yAxis = useRef<SVGGElement>(null);
  const x = useMemo(
    () =>
      scaleBand(
        data.map((d) => d.label),
        [padding.top, height - padding.bottom]
      )
        .paddingInner(barPadding.inner)
        .paddingOuter(barPadding.outer),
    [
      barPadding.inner,
      barPadding.outer,
      data,
      height,
      padding.bottom,
      padding.top,
    ]
  );
  const y = useMemo(
    () =>
      scaleLinear(domain, [padding.left, width - padding.left - padding.right]),
    [domain, padding.left, padding.right, width]
  );

  useEffect(() => {
    if (xAxis.current === null) {
      return;
    }
    select(xAxis.current).call(axisLeft(x));
  }, [xAxis, x]);
  useEffect(() => {
    if (yAxis.current === null) {
      return;
    }
    select(yAxis.current).call(axisBottom(y));
  }, [yAxis, y]);

  const colors =
    customColors ??
    scaleOrdinal()
      .domain(data.map((d) => d.label))
      .range(
        quantize(
          (t) => interpolateSpectral(t * 0.8 + 0.1),
          data.length
        ).reverse()
      );

  const drawCustom = useMemo(() => {
    return customDrawing
      ? customDrawing({
          bars: data,
          chartDimensions,
          scaleX: x,
          scaleY: y,
          colorScale: colors,
        })
      : undefined;
  }, [chartDimensions, colors, customDrawing, data, x, y]);

  const renderBars = (
    bars: Array<BarData>,
    dimensions: BarChartDimensions,
    scaleX: AxisScale<string> | AxisScale<number>,
    scaleY: AxisScale<string> | AxisScale<number>,
    colorScale: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>
  ) => {
    return bars.map(({ label, value }, i) => {
      if (label === undefined || value === undefined) {
        return <></>;
      }

      const xPos = scaleX(label as never) || 0;
      const xWidth = scaleX.bandwidth?.() || 0;
      const yPos = scaleY(value as never) || 0;
      const color = `${colorScale(i as never)}` || '#ABABAB';
      return (
        <React.Fragment key={`bar-${label}`}>
          <text
            x={padding.left + yPos + 15}
            y={xPos + xWidth / 2}
            textAnchor="start"
            dominantBaseline="middle"
            fontSize={15}
            fill="black"
          >
            {value.toLocaleString('en', { maximumFractionDigits: 0 })} {units}
            {value !== 1 ? 's' : null}
          </text>
          <rect
            x={scaleY(0 as never)}
            width={yPos}
            y={xPos}
            height={xWidth}
            fill={color}
          />
        </React.Fragment>
      );
    });
  };

  return data === undefined ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg viewBox={`0 0 ${width} ${height}`}>
      {!hideXAxis && (
        <g ref={xAxis} transform={`translate(${padding.left},0)`} />
      )}
      {!hideYAxis && (
        <g ref={yAxis} transform={`translate(0,${height - padding.bottom})`} />
      )}
      {drawCustom}
      {renderBars(data, chartDimensions, x, y, colors)}
    </svg>
  );
};
