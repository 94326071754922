import React from 'react';
import { EmailAlias } from 'models/email-alias';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import styles from './sidebar.module.css';

export const Sidebar: React.FC<{
  emailAlias: EmailAlias;
  archive: (emailAliasId: string) => void;
  unarchive: (emailAliasId: string) => void;
}> = ({ emailAlias, archive, unarchive }) => {
  return (
    <div className={styles.wrapper}>
      {!emailAlias.default && (
        <div className={styles.row}>
          <button
            type="button"
            onClick={() => {
              if (emailAlias.status === 'active') {
                archive(emailAlias.id);
              } else {
                unarchive(emailAlias.id);
              }
            }}
            className={styles.action}
          >
            <span>
              <SVGIcon name="Folder" />
            </span>
            <span>
              {emailAlias.status === 'active' ? 'Archive' : 'Unarchive'}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
