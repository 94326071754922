import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { EmailAlias } from 'models/email-alias';
import { WithPermission } from 'shared/WithPermission';
import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { useActions } from './useActions';

export const NewEmailAlias: React.FC<RouteComponentProps> = () => {
  const [emailAlias, setEmailAlias] = React.useState<Partial<EmailAlias>>({});
  const { create } = useActions();

  function onChange(updatedData: Partial<EmailAlias>) {
    setEmailAlias({ ...(emailAlias as EmailAlias), ...updatedData });
  }

  const header = (
    <>
      <h1 className="page-header">Email Alias</h1>
    </>
  );

  const main = (
    <Form emailAlias={emailAlias as EmailAlias} onChange={onChange} />
  );

  function onSave() {
    create(emailAlias);
  }

  return (
    <WithPermission permissions={['confContentAccess']}>
      <Helmet>
        <title>New Email Alias</title>
      </Helmet>
      {emailAlias && (
        <MainEditor
          header={header}
          main={main}
          sidebar={<></>}
          footer={<Footer emailAlias={emailAlias} action={onSave} />}
        />
      )}
    </WithPermission>
  );
};
