import React from 'react';
import cx from 'classnames';
import styles from '../MetricsCards.module.css';
import { CampaignResultsData } from '../../../../../../../services/api-insights';
import { HeatMap } from '../../../../../../../shared/Charts/HeatMap';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IEmailOpenTimeCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const dimensions = {
  height: 251,
  width: 528,
  padding: {
    top: 20,
    bottom: 0,
    right: 0,
    left: 80,
  },
};
const Y_KEYS = [
  'Saturday',
  'Friday',
  'Thursday',
  'Wednesday',
  'Tuesday',
  'Monday',
  'Sunday',
];
const X_KEYS = ['12am - 6am', '6am - 12pm', '12pm - 6pm', '6pm - 12am'];

export const EmailOpenTimeCard: React.FC<IEmailOpenTimeCard> = ({
  data,
  isLoading,
}) => {
  const graphData = data?.campaign.email_open_timeframes;

  return (
    <div className={cx(styles.metricCard, styles.metricCardHalf)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>When email opens happened</h1>
      </div>
      {isLoading ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={dimensions} />
        </div>
      ) : (
        <div className={styles.metricCardBody}>
          <HeatMap
            data={{
              xKeys: X_KEYS,
              yKeys: Y_KEYS,
              defaultValue: 0,
              data: graphData,
            }}
            chartDimensions={dimensions}
          />
        </div>
      )}
    </div>
  );
};
