import { Flex } from 'DesignSystem/Layout/Flex';
import React from 'react';
import { CloseButton } from 'shared/NavigationBars/CloseButton';
import { UpperBar, LowerBar } from 'shared/NavigationBars/FixedBars';
import { NavigationFooter } from 'shared/NavigationFooter';
import * as Type from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import styles from './styles.module.css';

export const NavigationBars: React.FC<React.ComponentProps<
  typeof NavigationFooter
>> = (props) => {
  const { exitHandler, action, canPerformAction } = props;

  const handleClose = React.useCallback(() => {
    if (exitHandler) exitHandler();
  }, [exitHandler]);

  const saveAndClose = React.useCallback(() => {
    if (action) {
      action();
    }
  }, [action]);

  return (
    <>
      <UpperBar>
        <Flex>
          <Type.Heading>Create journey Content</Type.Heading>
        </Flex>
        <Flex end>
          <CloseButton handleClose={handleClose} />
        </Flex>
      </UpperBar>

      <LowerBar>
        <Flex className={styles.footer} end>
          <Button
            label="Save and Close"
            onClick={saveAndClose}
            disabled={!canPerformAction}
          />
        </Flex>
      </LowerBar>
    </>
  );
};
