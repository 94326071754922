import React, { useMemo } from 'react';
import cx from 'classnames';
import { scaleOrdinal } from 'd3';
import { DateTime, Interval } from 'luxon';
import styles from '../MetricsCards.module.css';
import {
  CustomDrawingFunction,
  LineChart,
  LineData,
  PointData,
} from '../../../../../../../shared/Charts/LineChart';
import { CampaignResultsData } from '../../../../../../../services/api-insights';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IPerformanceTrendCard {
  data: null | CampaignResultsData;
  startDate: DateTime;
  keyFilter: Array<string>;
  isLoading: boolean;
}

const dimensions = {
  height: 240,
  width: 1118,
  padding: {
    top: 24,
    bottom: 24,
    left: 24,
    right: 0,
  },
};

export const PerformanceTrendCard: React.FC<IPerformanceTrendCard> = ({
  data,
  startDate,
  keyFilter,
  isLoading,
}) => {
  const graphData = data?.campaign.performance_trend;
  const targeted = data?.campaign.stats.targeted || 1;
  const filteredGraphData = useMemo(() => {
    return (
      graphData?.filter((line) => {
        return keyFilter.includes(line.label) && line.data.length > 1;
      }) || []
    );
  }, [graphData, keyFilter]);

  const currentDay = Math.min(
    Interval.fromDateTimes(startDate, DateTime.now()).length('day'),
    30
  );

  const verifiedData = useMemo(() => {
    return filteredGraphData.map((line) => {
      if (line.data.length === currentDay) {
        return line;
      }

      const fixedData: PointData[] = [];
      for (let i = 0; i <= currentDay; i += 1) {
        const date = startDate.plus({ hours: 24 * i }).toISODate();

        const foundDate = line.data.find(({ label }) => label === date);

        if (foundDate) {
          fixedData.push(foundDate);
        } else {
          fixedData.push({
            label: date ?? '',
            value: 0,
          });
        }
      }
      return {
        label: line.label,
        data: fixedData,
      };
    });
  }, [currentDay, filteredGraphData, startDate]);

  const color = scaleOrdinal()
    .domain(filteredGraphData.map((d) => d.label))
    .range([
      'rgba(254, 209, 94, 1)',
      'rgba(182, 228, 109, 1)',
      'rgba(112, 214, 236, 1)',
    ]);

  const generateKeys = (lineData: LineData[]) => {
    return lineData.map((line) => {
      return (
        <div className={styles.metricCardLegendLabel} key={`key-${line.label}`}>
          <svg
            className={cx(styles.icon, styles.circle)}
            width={10}
            height={10}
            viewBox="0 0 100 100"
          >
            <circle cx="50" cy="50" r="50" fill={color(line.label) as string} />
          </svg>
          <span>{line.label}</span>
        </div>
      );
    });
  };

  const drawLedgerLine = (
    text: string,
    x: number,
    y: number,
    width: number
  ) => (
    <g>
      <text
        x={x - 16}
        y={y}
        textAnchor="end"
        dominantBaseline="middle"
        fill="rgba(3, 2, 41, 0.7)"
        fontSize={12}
      >
        {text}
      </text>
      <line x1={x} y1={y} x2={width} y2={y} stroke="rgba(3, 2, 41, 0.05)" />
    </g>
  );

  const drawLedgerLines: CustomDrawingFunction = ({
    chartDimensions,
    scaleY,
    scaleX,
  }) => {
    const {
      width,
      padding: { left, right },
    } = chartDimensions;
    if (scaleX === undefined || scaleY === undefined) {
      return <></>;
    }

    const startX = scaleX(0 as never) || 0;
    const endX = width - left - right;
    const scale = targeted / 5;

    const lines = [];
    for (let i = 0; i <= 5; i += 1) {
      lines.push(
        drawLedgerLine(
          `${i * 20}%`,
          startX,
          scaleY((scale * i) as never) || 0,
          endX
        )
      );
    }

    return <>{lines}</>;
  };

  const drawDayMarkers: CustomDrawingFunction = ({
    chartDimensions,
    scaleX,
  }) => {
    const {
      height,
      padding: { bottom },
    } = chartDimensions;
    if (scaleX === undefined) {
      return <></>;
    }
    const fontSize = 12;
    const {
      width,
      padding: { left, right },
    } = chartDimensions;

    const startX = scaleX(0 as never) || 0;
    const endX = width - left - right;

    return (
      <>
        <text
          x={startX}
          y={height - bottom}
          dominantBaseline="hanging"
          fill="rgba(3, 2, 41, 0.7)"
          fontSize={fontSize}
        >
          Day 0
        </text>
        <text
          x={endX}
          y={height - bottom}
          textAnchor="end"
          dominantBaseline="hanging"
          fill="rgba(3, 2, 41, 0.7)"
          fontSize={fontSize}
        >
          Day {currentDay.toFixed(0)}
        </text>
      </>
    );
  };

  const drawCustomAxis: CustomDrawingFunction = ({
    chartDimensions,
    scaleX,
    scaleY,
  }) => {
    return (
      <>
        {drawLedgerLines({ chartDimensions, scaleY, scaleX })}
        {drawDayMarkers({ chartDimensions, scaleX })}
      </>
    );
  };

  const domain: [number, number] = [0, targeted];

  return (
    <div className={cx(styles.metricCard, styles.metricCardFull)}>
      <div className={styles.metricCardHeader}>
        <div className={styles.row}>
          <h1 className={styles.metricCardText}>Performance Trend</h1>
          <div className={styles.metricCardLegend}>
            {generateKeys(filteredGraphData)}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={dimensions} />
        </div>
      ) : (
        <div className={styles.metricCardBody}>
          <LineChart
            lines={verifiedData}
            domain={domain}
            customColors={color}
            customDrawing={drawCustomAxis}
            chartDimensions={dimensions}
            hideXAxis
            hideYAxis
          />
        </div>
      )}
    </div>
  );
};
