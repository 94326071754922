import { SettingsEditorConfig } from 'contexts/publisher/orchestrate/settings';
import { Audience } from 'models/audience';

import {
  calculateDuration,
  initializeNotificationsDateArray,
  Notification,
  sortNotifications,
} from 'models/notification';

import {
  Duration,
  DURATION_DAYS,
  DURATION_DAYS_BY_VALUE,
} from 'models/duration';
import {
  withEmailOnly,
  defaultChannels,
  withRestrictedFields,
  withTopicOnly,
  DeliveryChannels,
  allExceptFeedDisabled,
  someExceptFeedEnabled,
  withProgramChannelDefaults,
  withModifiers as withChannelModifiers,
} from 'models/channel';
import {
  DeliveryType,
  DELIVERY_TYPES,
  HYBRID,
  OPTIMIZE,
  OVERRIDE,
} from 'models/delivery-type';
import { PRIORITIES_BY_NAME } from 'models/priority';
import { Topic } from 'models/topic';
import {
  EmailSenderAlias,
  FEATURED_LABELS,
  Settings,
} from 'models/publisher/settings';
import { DateTime } from 'luxon';
import { ContentPermissions } from 'models/content-permission';
import {
  enabledEngagementBoost,
  EngagementBoost,
  withEngagementBoostDefaults,
  withModifiers as withEngBoostModifiers,
} from 'models/publisher/engagement-boost';

type Retries = number;

// TODO: Remove:
//  - 'value' - Should get value from settings in state
//  - 'sync' - Updating should set the correct overall state
//  - 'options' - Inconsistently used. Should just import them when needed.
//  - 'enabled' - Inconsistently used.
type FieldSettingsReturn<T> = {
  enabled: boolean;
  options: typeof DURATION_DAYS | Array<string>;
  editable?: boolean;
  default?: T;
  value?: T;
  set: (value: T, additionalSettings?: Partial<Settings>) => void;
  sync?: () => void;
};

export const duration = (
  config: SettingsEditorConfig
): FieldSettingsReturn<Duration> => {
  const editableAndEnabled =
    config.permissions.campaignDurationAccess &&
    config.settings.audiences.length > 0 &&
    config.settings.retries !== 0;

  return {
    enabled: editableAndEnabled,
    editable: editableAndEnabled,
    set: (value) => {
      config.update({ duration: value });
    },
    value: config.settings.duration,
    options: DURATION_DAYS,
  };
};

export const notifDeliveryTimesEnabled = (
  config: SettingsEditorConfig
): FieldSettingsReturn<boolean> => {
  const { settings } = config;
  const { notifications, publishedAt } = settings;
  const defaultValue = !!(notifications[0] && notifications[0].dateTime);

  return {
    default: defaultValue,
    enabled: true,
    set: (isEnabled) => {
      if (isEnabled) {
        const newNotifications = initializeNotificationsDateArray(
          notifications,
          publishedAt || DateTime.now()
        );

        config.update({
          notifications: newNotifications,
          notifDeliveryTimesEnabled: isEnabled,
        });
      } else {
        config.update({ notifDeliveryTimesEnabled: isEnabled });
      }
    },
    options: [],
  };
};

export const deliveryType = (
  config: SettingsEditorConfig
): FieldSettingsReturn<DeliveryType> => {
  const { update, settings } = config;
  const hasAudience = settings.audiences.length > 0;

  return {
    default: undefined,
    enabled: hasAudience,
    set: (value) => {
      switch (value) {
        case OVERRIDE:
          update({
            deliveryType: value,
            notifDeliveryTimesEnabled: false,
            retries: 0,
            optimizedDeliveryEnabled: false,
            duration: undefined,
          });
          break;
        case HYBRID:
          update({
            deliveryType: value,
            retries:
              !!settings.notifDeliveryTimesEnabled || settings.retries === 0
                ? -1
                : settings.retries,
            optimizedDeliveryEnabled: false,
            duration: !settings.duration
              ? DURATION_DAYS_BY_VALUE[14]
              : settings.duration,
          });
          break;
        case OPTIMIZE:
          update({
            deliveryType: value,
            notifDeliveryTimesEnabled: false,
            retries: -1,
            optimizedDeliveryEnabled: true,
            duration: !settings.duration
              ? DURATION_DAYS_BY_VALUE[14]
              : settings.duration,
          });
          break;
        default:
          break;
      }
    },
    options: DELIVERY_TYPES,
  };
};

export const retries = (
  config: SettingsEditorConfig
): FieldSettingsReturn<Retries> => {
  const { settings } = config;
  return {
    default: -1,
    enabled: true,
    set: (value) => {
      config.update({
        retries: value,
        duration:
          value === 0 && !settings.optimizedDeliveryEnabled
            ? undefined
            : settings.duration ?? DURATION_DAYS_BY_VALUE[14],
      });
    },
    options: [],
  };
};

export const optimizedDeliveryEnabled = (
  config: SettingsEditorConfig
): FieldSettingsReturn<boolean | undefined> => {
  return {
    default: undefined,
    enabled: true,
    options: [],
    set: (value?: boolean) => {
      config.update({ optimizedDeliveryEnabled: value });
    },
  };
};

export const notifications = (
  config: SettingsEditorConfig
): FieldSettingsReturn<Notification[]> => {
  const { update, settings } = config;

  const getNotifications = (): Notification[] => {
    if (settings.retries !== 0) {
      return settings.notifications;
    }
    return settings.notifications.slice(0, 1);
  };

  return {
    enabled: true,
    set: (newNotifications) => {
      let newDuration;
      let finalNotifications = [...newNotifications];

      if (config.settings.notifDeliveryTimesEnabled) {
        finalNotifications = initializeNotificationsDateArray(
          newNotifications,
          settings.publishedAt || DateTime.now()
        );
        if (finalNotifications.length > 1) {
          finalNotifications = sortNotifications(finalNotifications);
          newDuration = calculateDuration(finalNotifications);
        }
      }

      if (newDuration && settings.deliveryPageVersion !== 2) {
        update({
          notifications: finalNotifications,
          duration: newDuration,
        });
      } else {
        update({
          notifications: finalNotifications,
        });
      }
    },
    sync: () => {},
    value: getNotifications(),
    options: [],
  };
};

export const audiences = (
  config: SettingsEditorConfig & { contentPermissions: ContentPermissions }
): FieldSettingsReturn<Audience[]> => {
  const { settings, featureFlags, permissions, cloudbeesFlags } = config;

  return {
    default: [],
    enabled: !!settings.duration,
    set: (newAudiences, additionalSettings = {}) => {
      if (newAudiences.length > 0 && settings.audiences.length === 0) {
        // TODO: This should be pulling from emailOnly FeatureFlag
        const isEmailOnly = !!featureFlags?.emailTokenAuthEnabled?.value;

        const deliveryChannels = withChannelModifiers(
          [
            withProgramChannelDefaults(
              featureFlags?.channelDefaults?.value,
              !!cloudbeesFlags?.setChannelDefaultsEnabled?.value
            ),
            withEmailOnly(isEmailOnly),
            withRestrictedFields(permissions),
          ],
          defaultChannels
        );
        const engBoost = withEngBoostModifiers(
          [
            withEngagementBoostDefaults(
              featureFlags?.engagementBoostDefaults?.value,
              !!cloudbeesFlags.engagementBoostDefaultEnabled?.value
            ),
          ],
          enabledEngagementBoost
        );

        config.update({
          ...additionalSettings,
          ...engBoost,
          audiences: newAudiences,
          priority: additionalSettings.priority ?? PRIORITIES_BY_NAME.SHOULD,
          deliveryChannels,
          deliveryType: OPTIMIZE,
          includeInForYou: true,
          duration: DURATION_DAYS_BY_VALUE[14],
          limitVisibilityToAudience:
            additionalSettings.limitVisibilityToAudience ?? false,
        });
      } else if (newAudiences.length === 0) {
        config.update({
          ...additionalSettings,
          audiences: newAudiences,
          includeInForYou: undefined,
          priority: undefined,
          deliveryChannels: withTopicOnly(true)(defaultChannels),
          deliveryType: undefined,
          retries: 0,
          optimizedDeliveryEnabled: undefined,
          duration: undefined,
          limitVisibilityToAudience: false,
        });
      } else {
        config.update({ ...additionalSettings, audiences: newAudiences });
      }
    },
    options: [],
  };
};

export const contentTopics = (
  config: SettingsEditorConfig
): FieldSettingsReturn<Topic[]> => {
  const { settings } = config;

  return {
    default: [],
    enabled: true,
    set: (newTopics, additionalSettings = {}) => {
      if (
        newTopics.length === 0 &&
        (settings.isFeatured || settings.isResource)
      ) {
        config.update({
          contentTopics: newTopics,
          isFeatured: false,
          isResource: false,
          contentLabel: FEATURED_LABELS[0],
        });
      } else if (
        newTopics.length > 0 &&
        settings.contentTopics.length === 0 &&
        settings.audiences.length === 0
      ) {
        config.update({
          ...additionalSettings,
          contentTopics: newTopics,
          priority: undefined,
          deliveryType: undefined,
          duration: undefined,
          deliveryChannels: withTopicOnly(true)(settings.deliveryChannels),
          retries: 0,
        });
      } else {
        config.update({ ...additionalSettings, contentTopics: newTopics });
      }
    },
    options: [],
  };
};

export const includeInForYou = (
  config: SettingsEditorConfig
): FieldSettingsReturn<boolean> => {
  const hasAudience = config.settings.audiences.length > 0;
  return {
    default: true,
    enabled: hasAudience,
    set: (value) => {
      // feed and for you are lock-step
      config.update({
        includeInForYou: value,
        deliveryChannels: {
          ...config.settings.deliveryChannels,
          feed: value,
        },
      });
    },
    sync: () => {
      if (hasAudience && config.settings.includeInForYou === undefined) {
        config.update({
          includeInForYou: true,
        });
      }
    },
    options: [],
  };
};

export const engagementBoost = (
  config: SettingsEditorConfig
): FieldSettingsReturn<EngagementBoost> => {
  const { settings } = config;
  return {
    set: (engBoost) => {
      config.update({
        retries: engBoost.retries,
        optimizedDeliveryEnabled: engBoost.optimizedDeliveryEnabled,
        duration:
          engBoost.retries === 0 && !engBoost.optimizedDeliveryEnabled
            ? undefined
            : settings.duration ?? DURATION_DAYS_BY_VALUE[14],
      });
    },
    enabled: true,
    options: [],
  };
};

export const deliveryChannels = (
  config: SettingsEditorConfig
): FieldSettingsReturn<{
  deliveryChannels: DeliveryChannels;
  emailSenderAlias?: EmailSenderAlias;
  includeInForYou: boolean;
  notifications: Notification[];
}> => {
  const { settings, update, featureFlags, cloudbeesFlags } = config;
  return {
    set: (values) => {
      const allWereDisabled = allExceptFeedDisabled(settings.deliveryChannels);
      const someWereEnabled = someExceptFeedEnabled(settings.deliveryChannels);
      const allWillBeDisabled = allExceptFeedDisabled(values.deliveryChannels);
      const someWillBeEnabled = someExceptFeedEnabled(values.deliveryChannels);

      const toSet = {
        deliveryChannels: values.deliveryChannels,
        emailSenderAlias: values.emailSenderAlias,
        includeInForYou: values.includeInForYou,
        notifications: sortNotifications(values.notifications),
      };

      if (allWereDisabled && someWillBeEnabled) {
        const engBoost = withEngBoostModifiers(
          [
            withEngagementBoostDefaults(
              featureFlags?.engagementBoostDefaults?.value,
              !!cloudbeesFlags.engagementBoostDefaultEnabled?.value
            ),
          ],
          enabledEngagementBoost
        );

        update({
          ...toSet,
          ...engBoost,
          duration: settings.duration ?? DURATION_DAYS_BY_VALUE[14],
        });
      } else if (someWereEnabled && allWillBeDisabled) {
        update({
          ...toSet,
          retries: 0,
          optimizedDeliveryEnabled: false,
          duration: undefined,
        });
      } else {
        update(toSet);
      }
    },
    enabled: true,
    options: [],
  };
};
