import React, { useEffect } from 'react';
import { useNavigate } from '@reach/router';
import { Flex } from 'DesignSystem/Layout/Flex';
import { ConfirmModal } from 'DesignSystem/Components';
import { ChevronDown } from 'shared/icons';
import { HoverIconMenu, ItemType } from 'shared/hover-dropdown/HoverIconMenu';
import { StatusColor } from 'shared/NavigationBars/utils';
import statusStyles from 'shared/NavigationBars/navigation-bars.module.css';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Journey, JourneyMode } from 'models/journeys/journey';
import {
  useJourneyCreateDraftAction,
  useJourneyDeleteDraftAction,
  useJourneyStopAction,
} from 'hooks/journeys/useJourneyActions';
import { useProgram } from 'contexts/program';
import { useJourneyState } from 'contexts/journey';
import classNames from 'classnames';
import { Text } from 'DesignSystem/Typography';
import { useCancelJourneyProcessing } from 'hooks/journeys/journeys';
import { asserts } from 'utility/asserts';
import styles from './journey-mode-switcher.module.css';
import {
  CloudStatus,
  useJourneyPersistenceStatus,
} from '../JourneyCanvasHeader/useJourneyPersistenceStatus';
import JourneyDangerActionModal from '../JourneyModal/JourneyDangerActionModal';

type JourneyStatusTagMenuProps = {
  journey: Journey;
  mode: JourneyMode;
};

const JourneyStatusTagMenu: React.FC<JourneyStatusTagMenuProps> = ({
  journey,
  mode,
}) => {
  const [showCloseModal, setShowCloseModal] = React.useState(false);
  const [showStopWarning, setShowStopWarning] = React.useState(false);
  const [showDeleteDraftWarning, setShowDeleteDraftWarning] = React.useState(
    false
  );
  const navigate = useNavigate();

  const { id: programId } = useProgram();
  const { draftCreatingProcess, setDraftCreatingProcess } = useJourneyState();

  const dismissCloseModal = () => {
    setShowCloseModal(false);
  };

  const dismissStopWarning = () => {
    setShowStopWarning(false);
  };

  const dismissDeleteDraftWarning = () => {
    setShowDeleteDraftWarning(false);
  };

  const navigateToLive = () =>
    navigate(`/${programId}/app/journeys/${journey.id}`);

  const persistenceStatus = useJourneyPersistenceStatus();

  const {
    call: createDraft,
    isLoading: isDraftCreating,
  } = useJourneyCreateDraftAction(journey);

  useEffect(() => {
    setDraftCreatingProcess(isDraftCreating);
  }, [setDraftCreatingProcess, isDraftCreating]);

  const {
    call: stopJourney,
    isLoading: isStoppingJourney,
  } = useJourneyStopAction(
    journey,
    () => {
      dismissStopWarning();
      navigate(`/${programId}/app/journeys`);
    },
    dismissStopWarning
  );

  const {
    call: deleteJourneyDraft,
    isLoading: isDeletingJourneyDraft,
  } = useJourneyDeleteDraftAction(
    journey,
    () => {
      dismissDeleteDraftWarning();
      navigate(`/${programId}/app/journeys`);
    },
    dismissDeleteDraftWarning
  );

  const {
    cancelJourneyProcessing,
    status: cancelJourneyProcessingStatus,
  } = useCancelJourneyProcessing();

  const isCancellingJourneyProcessing =
    cancelJourneyProcessingStatus === 'loading';

  const cancelPublishingItem: ItemType = {
    title: isCancellingJourneyProcessing ? 'Cancelling...' : 'Cancel Publish',
    onClick: () => {
      asserts(journey.id !== undefined, 'Journey ID must be defined');
      cancelJourneyProcessing(journey.id);
    },
    disabled: isCancellingJourneyProcessing,
  };

  const openActiveAction =
    persistenceStatus.cloudStatus === CloudStatus.UpToDate
      ? { href: `/${programId}/app/journeys/${journey.id}` }
      : { onClick: () => setShowCloseModal(true) };

  // Only when the journey is in edit mode and has a live graph
  const openActive: ItemType = {
    title: 'Open Active',
    ...openActiveAction,
  };

  // Only when the journey is in view mode
  const stopJourneyItem: ItemType = {
    title: isStoppingJourney ? 'Stopping...' : 'Stop Journey...',
    danger: true,
    onClick: () => setShowStopWarning(true),
  };

  // Only when the journey is in view mode
  const deleteJourneyDraftGraph: ItemType = {
    title: isStoppingJourney ? 'Deleting...' : 'Delete Draft...',
    danger: true,
    onClick: () => setShowDeleteDraftWarning(true),
  };

  // Only when the journey is in view mode and has no draft graph
  const createDraftItem: ItemType = {
    title: draftCreatingProcess ? 'Creating...' : 'Create Draft',
    onClick: createDraft,
    disabled: draftCreatingProcess,
  };

  // Only when the journey is in view mode and has a draft graph
  const openDraft: ItemType = {
    title: 'Open Draft',
    href: `/${programId}/app/journeys/${journey.id}/edit`,
  };

  // Only when the journey is in edit mode and has a draft graph and live graph
  // TODO: Implement delete draft
  // const deleteDraft: ItemType = {
  //   title: 'Delete Draft...',
  //   danger: true,
  //   onClick: () => {

  //   },
  // };

  const isLoading = draftCreatingProcess || isStoppingJourney;
  const isJourneyProcessing = journey.state === 'processing';
  const menuItems: ItemType[] = [];

  if (!isLoading && !isJourneyProcessing) {
    if (mode === JourneyMode.Edit) {
      if (journey.liveGraph) {
        menuItems.push(openActive);

        if (journey.draftGraph) {
          menuItems.push(deleteJourneyDraftGraph);
        }
      }
    } else if (mode === JourneyMode.View && journey.state !== 'archived') {
      menuItems.push(stopJourneyItem);

      if (journey.draftGraph) {
        menuItems.push(openDraft);
      } else {
        menuItems.push(createDraftItem);
      }
    }
  } else if (isJourneyProcessing) {
    menuItems.push(cancelPublishingItem);
  }

  let status: string;
  let statusColor: StatusColor;
  if (journey.state === 'archived') {
    status = 'Archived';
    statusColor = StatusColor.archived;
  } else if (isJourneyProcessing) {
    status = 'Publishing';
    statusColor = StatusColor.publishing;
  } else if (mode === JourneyMode.Edit) {
    status = 'Draft';
    statusColor = StatusColor.draft;
  } else {
    status = 'Active';
    statusColor = StatusColor.active;
  }

  const statusText = (
    <Text
      className={{
        gray90: true,
      }}
    >
      {status}
    </Text>
  );

  return (
    <>
      {showCloseModal && (
        <ConfirmModal
          title="Unsaved changes"
          confirmLabel="Proceed"
          onConfirm={navigateToLive}
          onCancel={dismissCloseModal}
        >
          This draft has unsaved changes. Are you sure that you want to exit?
        </ConfirmModal>
      )}

      {showStopWarning && (
        <JourneyDangerActionModal
          title={`Stop ${journey.name}`}
          confirmLabel="Yes, Stop Journey"
          description="This will remove all members and turn the active journey into a draft. It
          cannot be undone."
          isLoading={isStoppingJourney}
          onConfirm={stopJourney}
          onCancel={dismissStopWarning}
        />
      )}

      {showDeleteDraftWarning && (
        <JourneyDangerActionModal
          title={`Delete Draft of ${journey.name}`}
          confirmLabel="Yes, Delete Journey Draft"
          description="This will delete journey graph. It cannot be undone."
          isLoading={isDeletingJourneyDraft}
          onConfirm={deleteJourneyDraft}
          onCancel={dismissDeleteDraftWarning}
        />
      )}

      <div className={styles.statusTagContainer}>
        {menuItems.length > 0 ? (
          <HoverIconMenu
            menuItems={menuItems}
            dropdownClassName="dropdown-align-right"
            openDelay="click"
            ariaLabel={status}
          >
            <StatusTag hoverable color={statusColor} after={<ChevronDown />}>
              {statusText}
            </StatusTag>
          </HoverIconMenu>
        ) : (
          <StatusTag color={statusColor}>
            {isLoading ? <LoadingSpinner size="small" /> : statusText}
          </StatusTag>
        )}
        {isJourneyProcessing && <LoadingSpinner size="small" color="blue" />}
      </div>
    </>
  );
};

type StatusTagProps = {
  children: React.ReactNode;
  after?: React.ReactNode;
  color?: string;
  hoverable?: boolean;
};
function StatusTag({
  children,
  after,
  color,
  hoverable = false,
}: StatusTagProps) {
  const statusTagClassNames = classNames(
    statusStyles.statusTag,
    styles.statusTag,
    hoverable && styles.hoverable
  );
  return (
    <Flex className={statusTagClassNames} style={{ backgroundColor: color }}>
      {children}
      {after}
    </Flex>
  );
}

export default JourneyStatusTagMenu;
