import { TLinkItem } from 'models/shortcut';
import { FieldValidationError } from '../types';

function validateUrl(url: string): boolean {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

export function validateLink(value: TLinkItem): FieldValidationError {
  const validUrl = validateUrl(value.url ?? '');
  const validName = (value.label ?? '').length > 1;

  const error: FieldValidationError = {};

  if (!validUrl && value.url) {
    error.url = {
      message: 'Please provide valid URL including scheme',
    };
  }

  if (!validName && value.label) {
    error.name = {
      message: 'Please provide a name with at least 2 characters',
    };
  }

  return error;
}
