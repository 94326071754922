import React from 'react';
import { Box, FormModal } from 'DesignSystem/Components';
import { FieldInput, Radio } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { FetchedCaption, TranscriptionJob } from 'services/api-captions';
import styles from '../video.module.css';
import { useCaptionSettings } from '../../hooks/useCaptionSettings';
import { UploadedCaptionsForm } from './UploadedCaptionsForm';
import {
  useCaptionsJobs,
  useTranscribeVideo,
} from '../../hooks/useCaptionsJobs';

export type CaptionSettingsModalProps = {
  onClose: () => void;
  onFileSelect: (captionFile: File) => void;
  onCaptionRemove: (url: string) => void;
  isCaptionsUploading: boolean;
  currentCaption?: FetchedCaption;
  videoId?: number;
  isDesignAsset: boolean;
};

export enum CaptionsType {
  GENERATE = 'generate',
  UPLOAD = 'upload',
}

export const CaptionSettings: React.FC<CaptionSettingsModalProps> = ({
  onClose,
  onFileSelect,
  onCaptionRemove,
  isCaptionsUploading,
  currentCaption,
  videoId,
  isDesignAsset,
}) => {
  const { id: programId } = useProgram();

  const {
    onFormSubmit: onUpload,
    captionText,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    isDisabled,
    validationErrors,
  } = useCaptionSettings({
    onFileSelect,
    onCaptionRemove,
    isCaptionsUploading,
    currentCaption,
  });
  const [captionsType, setCaptionsType] = React.useState<CaptionsType>(
    CaptionsType.UPLOAD
  );
  const {
    isCaptionTranscriptionEnabled,
    isLoadingFeatureFlags,
  } = useCaptionsJobs();
  const selectUpload = () => setCaptionsType(CaptionsType.UPLOAD);
  const selectGenerate = () => setCaptionsType(CaptionsType.GENERATE);
  const { setTranscriptionJob } = useCaptionsJobs();

  const handleStartTranscription = (job: TranscriptionJob) => {
    setTranscriptionJob(job);
  };

  const { startTranscription } = useTranscribeVideo({
    onSuccess: handleStartTranscription,
  });

  React.useEffect(() => {
    if (isCaptionTranscriptionEnabled) {
      setCaptionsType(CaptionsType.GENERATE);
    }
  }, [isCaptionTranscriptionEnabled]);

  if (isLoadingFeatureFlags) return null;
  return (
    <FormModal
      entityText={
        isCaptionTranscriptionEnabled && !captionText
          ? 'Add Captions'
          : 'Caption Settings'
      }
      actionText=""
      description={
        isCaptionTranscriptionEnabled
          ? 'Captions can be edited, translated, and downloaded after processing.'
          : ''
      }
      submitLabel="Apply"
      onSubmit={() => {
        if (captionsType === CaptionsType.GENERATE) {
          startTranscription({ programId, videoId, isDesignAsset });
        } else {
          onUpload();
        }
        onClose();
      }}
      onCancel={onClose}
      disabled={captionsType === CaptionsType.UPLOAD ? isDisabled : false}
    >
      <Box
        className={
          isCaptionTranscriptionEnabled
            ? styles.addCaptions
            : styles.captionsSettings
        }
      >
        {isCaptionTranscriptionEnabled && (
          <>
            {!captionText && (
              <>
                <Radio
                  type="radio"
                  label={
                    <Box margin={[0, 0, 0, -8]}>
                      <Text.Body>Automatically generate captions</Text.Body>
                    </Box>
                  }
                  checked={captionsType === CaptionsType.GENERATE}
                  onSelect={selectGenerate}
                />

                <Box padding={[16, 0]} className={styles.uploadCaptionsWrapper}>
                  <Radio
                    type="radio"
                    label={
                      <Flex className={styles.uploadCaptionsLabel} column start>
                        <Text.Body>Upload your own caption file</Text.Body>
                        <Text.Caption>
                          You can select multiple files at once for upload
                        </Text.Caption>
                      </Flex>
                    }
                    checked={captionsType === CaptionsType.UPLOAD}
                    onSelect={selectUpload}
                  />
                </Box>
              </>
            )}
            {(captionsType === CaptionsType.UPLOAD || captionText) && (
              <UploadedCaptionsForm
                captionText={captionText}
                validationErrors={validationErrors}
                onReplaceClick={onReplaceClick}
                onRemoveClick={onRemoveClick}
                onUploadClick={onUploadClick}
                fileInput={fileInput}
                errorMessage={errorMessage}
              />
            )}
          </>
        )}
        {!isCaptionTranscriptionEnabled && (
          <FieldInput>
            <UploadedCaptionsForm
              captionText={captionText}
              validationErrors={validationErrors}
              onReplaceClick={onReplaceClick}
              onRemoveClick={onRemoveClick}
              onUploadClick={onUploadClick}
              fileInput={fileInput}
              errorMessage={errorMessage}
            />
          </FieldInput>
        )}
      </Box>
    </FormModal>
  );
};
