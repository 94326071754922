import React from 'react';
import { ConfirmModal } from 'DesignSystem/Components';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WarningTriangle } from 'shared/icons';

interface JourneyDangerActionModalProps {
  title: string;
  confirmLabel: string;
  description: string;
  isLoading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const JourneyDangerActionModal: React.FC<JourneyDangerActionModalProps> = ({
  title,
  confirmLabel,
  description,
  isLoading,
  onConfirm,
  onCancel,
}) => {
  return (
    <ConfirmModal
      title={title}
      titleIcon={<WarningTriangle color="#dd1d1d" />}
      confirmLabel={isLoading ? '' : confirmLabel}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmIcon={isLoading ? <LoadingSpinner size="small" /> : undefined}
      disabled={isLoading}
      disableCancel={isLoading}
    >
      {description}
    </ConfirmModal>
  );
};

export default JourneyDangerActionModal;
