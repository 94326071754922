import React, { useEffect } from 'react';
// using esm import due to https://github.com/Stanko/react-animate-height/issues/148
import AnimateHeight from 'react-animate-height/dist/esm/index';
import {
  Box,
  DismissType,
  Popover,
  ClickDropdown,
} from 'DesignSystem/Components';
import {
  background,
  Body,
  border,
  Caption,
  Subheading,
  styles as tStyles,
} from 'DesignSystem/Typography';
import { usePermissions } from 'contexts/permissions';
import { Button } from 'DesignSystem/Form/InputElements';
import { Icon } from 'shared/Icon';
import { ReactComponent as Bolt } from 'shared/icons/Bolt.svg';
import { FlipSwitch } from 'shared/FlipSwitch';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { pluralize } from 'utility/text';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { ChangeDuration } from './ChangeDuration';
import { useDuration } from './useDuration';
import { SettingsCard } from '../../../SettingsCard';

const Card: React.FC<{
  title: string;
  description: string;
  disabled?: boolean;
  on?: boolean;
  dataTest?: string;
  onToggle: (value?: boolean) => void;
}> = ({
  title,
  description,
  children,
  disabled = false,
  on = false,
  dataTest,
  onToggle,
}) => {
  return (
    <Box relative margin={[0, 0, 26, 0]}>
      <Box margin={[0, 0, 0, 0]}>
        <Body bold>{title}</Body>
      </Box>
      <Caption>{description}</Caption>
      <Box dataTest={dataTest} absolute right={0} top={10}>
        <FlipSwitch disabled={disabled} onChange={onToggle} on={on} />
      </Box>
      {children}
    </Box>
  );
};

export const EngagementBoostSection: React.FC<{
  disabled?: boolean;
}> = ({ disabled = false }) => {
  const { id: programId } = useProgram();
  const optimizedDeliveryEnabledFlag = useFeatureFlagsQuery(
    programId,
    'Studio.Delivery.OptimizedDelivery.Enabled'
  );
  const {
    permissions: { sendEmailAccess, sendPushAccess, sendAssistantAccess },
  } = usePermissions();

  const {
    settings: {
      deliveryChannels: { email, push, assistant },
      duration,
      optimizedDeliveryEnabled,
      retries,
    },
    fields: {
      duration: setDuration,
      engagementBoost: setEngagementBoost,
      optimizedDeliveryEnabled: setOptimizedDeliveryEnabled,
      retries: setRetries,
    },
    contentPermissions: { canEdit },
  } = useSettings();
  useEffect(() => {
    if (
      setOptimizedDeliveryEnabled &&
      !optimizedDeliveryEnabledFlag.isLoading &&
      !optimizedDeliveryEnabledFlag.data?.value
    ) {
      setOptimizedDeliveryEnabled.set(false);
    }
  }, [setOptimizedDeliveryEnabled, optimizedDeliveryEnabledFlag]);

  const suggestedDuration = useDuration();

  const isEditable = React.useMemo(
    () =>
      (sendEmailAccess || sendPushAccess || sendAssistantAccess) &&
      (email || push || assistant) &&
      canEdit,
    [
      assistant,
      canEdit,
      email,
      push,
      sendEmailAccess,
      sendPushAccess,
      sendAssistantAccess,
    ]
  );

  const retriesEnabled = React.useMemo(() => retries === -1, [retries]);

  const isEnabled = React.useMemo(
    () =>
      !!(
        (retriesEnabled || optimizedDeliveryEnabled) &&
        (email || push || assistant)
      ),
    [assistant, email, optimizedDeliveryEnabled, push, retriesEnabled]
  );

  const toggleEngagementSettings = () => {
    const engBoost = !isEnabled
      ? { retries: -1, optimizedDeliveryEnabled: true }
      : { retries: 0, optimizedDeliveryEnabled: false };
    setEngagementBoost.set(engBoost);
  };

  const selectDurationDropdown = (
    dismiss: DismissType,
    setDismissable?: (dismissable: boolean) => void
  ) => {
    return (
      <Box width={512}>
        <Popover centered padding={0}>
          <ChangeDuration
            disabled={disabled}
            onCancel={dismiss}
            dismiss={dismiss}
            onSave={(newDuration) => {
              setDuration.set(newDuration);
            }}
            value={duration}
            suggestedValue={suggestedDuration}
            setDismissable={setDismissable}
          />
        </Popover>
      </Box>
    );
  };

  return (
    <SettingsCard
      showEditButton={false}
      topBorder
      isEditing={false}
      disabled={disabled}
      dataTest="engagement-boost-settings"
    >
      <Box
        relative
        margin={[0, 0, 0, 0]}
        border={[border.radius16]}
        background={background.gray05}
        padding={[26, 24, 1]}
      >
        <Box absolute right={15} top={26} bottom={5}>
          <Button
            compact
            label={isEnabled ? 'Turn Off' : 'Turn On'}
            disabled={!isEditable}
            onClick={toggleEngagementSettings}
          />
        </Box>
        <Box absolute left={27} top={27} height={20} width={20}>
          <Bolt />
        </Box>

        <Box margin={[0, 0, 25, 35]}>
          <Box margin={[0, 0, 0, 0]}>
            <Box margin={[0, 0, 8, 0]}>
              <Subheading bold>
                Engagement Boost: {isEnabled ? 'ON' : 'OFF'}
              </Subheading>
            </Box>
            <Caption>
              {isEnabled
                ? 'Optimize campaign delivery based on audience behavior, preferences, and competing communications.'
                : 'Campaign will be sent once at selected publish time'}
            </Caption>
          </Box>
          <AnimateHeight id="example-panel" height={isEnabled ? 'auto' : 0}>
            <Box height={25} />
            {!optimizedDeliveryEnabledFlag.isLoading &&
              optimizedDeliveryEnabledFlag.data?.value && (
                <Card
                  dataTest="optimized-toggle"
                  title={`Optimized Delivery: ${
                    optimizedDeliveryEnabled ? 'ON' : 'OFF'
                  }`}
                  description={
                    optimizedDeliveryEnabled
                      ? 'Campaign will be sent to audiences at the best time based on individual viewing habits'
                      : 'Campaign will be sent at selected publish time'
                  }
                  on={optimizedDeliveryEnabled}
                  onToggle={(value) => {
                    const toggleValue = value || false;
                    setOptimizedDeliveryEnabled.set(toggleValue);
                  }}
                  disabled={!isEditable}
                />
              )}
            <Card
              title={`Automatic Retargeting: ${retriesEnabled ? 'ON' : 'OFF'}`}
              dataTest="retargeting-toggle"
              description={
                retriesEnabled
                  ? 'Campaign will attempt to be re-sent to audiences that have not opened/acknowledged within selected duration'
                  : 'Campaign will not have any retargeting attempts'
              }
              onToggle={(_) => {
                setRetries.set(retriesEnabled ? 0 : -1);
              }}
              on={retriesEnabled}
              disabled={!isEditable}
            />

            {isEnabled && (
              <Box
                dataTest="duration-message"
                style={{ display: 'flex' }}
                className={tStyles.Body}
              >
                Duration for Delivery and Retargeting: {duration?.value}{' '}
                {pluralize(duration?.value || 1, duration?.unit.value)}
                &nbsp;
                {isEditable && (
                  <ClickDropdown
                    placement="right"
                    dropdownRenderProp={selectDurationDropdown}
                    asModal
                    disabled={!isEditable}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <Icon iconName="pencil" iconType="SVG" />
                    </div>
                  </ClickDropdown>
                )}
              </Box>
            )}
          </AnimateHeight>
        </Box>
      </Box>
    </SettingsCard>
  );
};
