import React, { useCallback, useEffect, useState } from 'react';
import { useUser } from 'contexts/user';
import {
  BulkActionType,
  BulkProcessingState,
  PublicationState,
} from 'models/content';
import { useFlashMessage } from 'contexts/flasher';
import {
  EDIT_PERMISSION_WARNING,
  FlashMessageType,
} from 'models/flash-message';
import { useQueryClient } from 'react-query';
import {
  FetchBulkActionActiveJobStatusData,
  FetchProps,
} from 'services/api-content';
import { FiltersStateType } from 'contexts/content/filters';
import { useProgram } from 'contexts/program';
import { Button } from '@socialchorus/shared-ui-components';
import {
  useCancelBulkProcessMutation,
  useCheckBulkActionActiveJob,
  useCheckBulkActionActiveJobStatus,
  useContentBulkStatus,
} from './content';
import { useProgressingFlash } from './useProgressingFlash';
import { BulkSelection } from './common';
import styles from './bulk-action.module.css';

type BulkActionProp = {
  currentState: PublicationState;
  bulkProcessingState: BulkProcessingState;
  setBulkProcessingState: (state: BulkProcessingState) => void;
  onBulkActionSuccess: () => void;
  onBeforeBulkActionCancellation: () => void;
  onJobCompletion: () => void;
};

export const useBulkAction = ({
  currentState,
  bulkProcessingState,
  setBulkProcessingState,
  onBulkActionSuccess,
  onBeforeBulkActionCancellation,
  onJobCompletion,
}: BulkActionProp): {
  bulkPublish: (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => boolean;
  bulkArchive: (
    bulkSelection: BulkSelection,
    fetchProps: FetchProps,
    filters: FiltersStateType
  ) => boolean;
  cancelBulkProcess: () => void;
  activeJob: string | undefined;
  isBulkActionApplied: boolean;
  processingContents: {
    [id: string]: {
      status: string;
    };
  };
  currentBulkAction: string | undefined;
  isProcessing: boolean;
} => {
  const { id: programId } = useProgram();
  const { id: currentUserId } = useUser();
  const [currentBulkAction, setCurrentBulkAction] = useState<BulkActionType>();
  const [activeJob, setActiveJob] = useState<string>();
  const [processingContents, setProcessingContents] = useState<{
    [id: string]: { status: PublicationState | 'processing' };
  }>({});
  const { setFlashMessage } = useFlashMessage();

  useCheckBulkActionActiveJob(
    {
      currentState,
      programId,
    },
    (jobData) => {
      if (jobData) {
        setActiveJob(jobData.jobId);
      }
    }
  );

  const onSuccess = useCallback(
    (jobId: string) => {
      onBulkActionSuccess();
      setActiveJob(jobId);
    },
    [onBulkActionSuccess]
  );
  const onError = useCallback(
    (message?: string) => {
      if (message) {
        if (message === 'forbidden') setFlashMessage(EDIT_PERMISSION_WARNING);
        else
          setFlashMessage({
            severity: 'error',
            message,
            timeout: false,
          });
      } else
        setFlashMessage({
          severity: 'error',
          message: 'There was an unexpected error.',
          details: 'Please reload the page to try again.',
          timeout: false,
        });
    },
    [setFlashMessage]
  );

  const {
    publish: bulkPublishContents,
    bulkArchive: bulkArchiveContents,
    isProcessing,
  } = useContentBulkStatus(
    programId,
    {
      onSuccess,
      onError,
    },
    currentState
  );

  const queryClient = useQueryClient();
  const {
    mutateCancelBulkProcess,
    isLoading: isCancelling,
  } = useCancelBulkProcessMutation((error) => {
    setFlashMessage({
      message: error ? error.message : 'Processing stopped',
      severity: 'error',
    });
    queryClient.invalidateQueries(['bulkActionActiveJobStatus']);
  });

  const cancelBulkProcess = useCallback(() => {
    if (activeJob && !isCancelling) {
      mutateCancelBulkProcess({ jobId: activeJob, programId });
    }
  }, [mutateCancelBulkProcess, isCancelling, programId, activeJob]);

  const setFlashMessageIfCurrentUser = useCallback(
    (
      actorIsCurrentUser: boolean,
      condition: boolean,
      flashMessage: FlashMessageType
    ) => {
      if (actorIsCurrentUser && condition) {
        setFlashMessage(flashMessage);
      }
    },
    [setFlashMessage]
  );

  const {
    progressingFlashDismiss,
    setProgressingFlash,
  } = useProgressingFlash();

  const progressingFlashInitialize = useCallback(
    (jobStatus: FetchBulkActionActiveJobStatusData) => {
      if (jobStatus) {
        setProgressingFlash({
          message: `Your bulk action is being processed.`,
          percentage: jobStatus.percentage,
          keyPrefix: `${jobStatus.jobId}`,
          children: jobStatus.isCanceled ? (
            <div className={styles.stopMessage}>Stopping...</div>
          ) : (
            <Button
              onClick={onBeforeBulkActionCancellation}
              className={styles.stopProcessingButton}
              label="Stop Processing"
              variant="text"
            />
          ),
        });
      }
    },
    [setProgressingFlash, onBeforeBulkActionCancellation]
  );

  const onJobStatusSuccess = useCallback(
    (jobStatus?: FetchBulkActionActiveJobStatusData) => {
      if (jobStatus) {
        const actorIsCurrentUser = currentUserId === jobStatus.userId;
        setProcessingContents(jobStatus.contentIds);

        if (
          jobStatus.status === 'completed' ||
          jobStatus.status === 'canceled'
        ) {
          progressingFlashDismiss(`${jobStatus.jobId}`);

          setFlashMessageIfCurrentUser(
            actorIsCurrentUser,
            jobStatus.totalExecuted > 0,
            {
              messageKey: `${jobStatus.jobId}-completed`,
              severity: 'info',
              message: `${jobStatus.totalExecuted} Campaigns ${jobStatus.nextState}`,
            }
          );

          setFlashMessageIfCurrentUser(
            actorIsCurrentUser,
            jobStatus.errored > 0,
            {
              messageKey: `${jobStatus.jobId}-errored`,
              severity: 'error',
              message: `${jobStatus.errored} Campaigns failed to ${
                jobStatus.nextState === 'archived' ? 'archive' : 'publish'
              }`,
            }
          );

          setBulkProcessingState(BulkProcessingState.COMPLETED);
          setActiveJob(undefined);
          onJobCompletion();
        } else {
          if (actorIsCurrentUser) {
            progressingFlashInitialize(jobStatus);
          }
          setBulkProcessingState(BulkProcessingState.PROCESSING);
        }
      } else {
        setActiveJob(undefined);
      }
    },
    [
      progressingFlashDismiss,
      progressingFlashInitialize,
      setBulkProcessingState,
      currentUserId,
      onJobCompletion,
      setFlashMessageIfCurrentUser,
    ]
  );

  useCheckBulkActionActiveJobStatus(
    {
      jobId: activeJob,
      programId,
    },
    onJobStatusSuccess
  );

  useEffect(() => {
    return () => progressingFlashDismiss(activeJob);
  }, [activeJob, progressingFlashDismiss]);

  const isBulkActionApplied =
    bulkProcessingState !== BulkProcessingState.INITIAL;

  const bulkPublish = useCallback(
    (
      bulkSelection: BulkSelection,
      fetchProps: FetchProps,
      filters: FiltersStateType
    ) => {
      setCurrentBulkAction(BulkActionType.PUBLISHING);
      bulkPublishContents(bulkSelection, fetchProps, filters);
      return true;
    },
    [bulkPublishContents]
  );

  const bulkArchive = useCallback(
    (
      bulkSelection: BulkSelection,
      fetchProps: FetchProps,
      filters: FiltersStateType
    ) => {
      setCurrentBulkAction(BulkActionType.ARCHIVING);
      bulkArchiveContents(bulkSelection, fetchProps, filters);
      return true;
    },
    [bulkArchiveContents]
  );

  return {
    bulkPublish,
    bulkArchive,
    cancelBulkProcess,
    activeJob,
    isBulkActionApplied,
    processingContents,
    currentBulkAction,
    isProcessing,
  };
};
