import * as React from 'react';
import { useTemplate } from 'contexts/template';
import { usePublisher } from 'contexts/publisher';
import { cloneVideo } from 'services/api-assets';
import { useVideoQuery, useVideoUpload } from 'hooks/video';
import { Video } from 'models/video';
import { UPLOAD } from 'models/source';
import { UploadProgress } from 'services/helpers/upload-to-s3';

type PropsType = {
  programId: number;
  isDesignAsset?: boolean;
  videoId?: number;
  onTranscoding: (video: Video) => void;
  onSuccess: (video: Video) => void;
};

type ReturnType = {
  upload: (file: File) => void;
  replace: (currentVideoId: number, file: File) => void;
  uploadedBytes?: number;
  totalBytes?: number;
  isUploading: boolean;
  isTranscoding: boolean;
  isCompleted: boolean;
  isErrored: boolean;
  isCaptionUpdated: boolean;
  transcodeJobPercentComplete?: number;
  errorMessage?: string;
  onCaptionUpdated: () => void;
  ensureVideoId: (newVideoId: number | undefined) => void;
};

enum UploaderStatus {
  Initializing,
  LoadingExistingVideo,
  Uploading,
  Uploaded,
  Transcoding,
  Completed,
  CaptionHasUpdated,
  Errored,
}

export function useVideoUploader(props: PropsType): ReturnType {
  const { programId, isDesignAsset, onTranscoding, onSuccess } = props;

  const [uploadProgress, setUploadProgress] = React.useState<
    UploadProgress | undefined
  >();
  const [videoId, setVideoId] = React.useState<number | undefined>(
    props.videoId
  );

  const [status, setStatus] = React.useState(UploaderStatus.Initializing);
  const [isUploadingCaptions, setIsUploadingCaptions] = React.useState(false);

  function onUploadProgress(progress: UploadProgress) {
    const { loaded, total } = progress;
    setUploadProgress({ loaded, total });
  }

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  const { mutate, isSaving, errorMessage: uploadErrorMessage } = useVideoUpload(
    {
      onSuccess: (id: number) => {
        setVideoId(id);
        setStatus(UploaderStatus.Uploaded);
      },
    }
  );

  React.useEffect(() => {
    setErrorMessage(uploadErrorMessage);
  }, [uploadErrorMessage]);

  const { data: video, refetch } = useVideoQuery({
    programId,
    isDesignAsset,
    videoId,
    enabled:
      status === UploaderStatus.Uploaded ||
      status === UploaderStatus.Transcoding ||
      status === UploaderStatus.LoadingExistingVideo ||
      status === UploaderStatus.CaptionHasUpdated,
    refetchInterval: 2000,
  });

  const template = useTemplate();
  const { post } = usePublisher();

  const isCreatingFromTemplate =
    template.source === 'template' &&
    !post.content.id &&
    template.id === Number(post.content.libraryTemplateId);

  const isVideoStatusCompleted = video?.status === 'completed';
  React.useEffect(() => {
    switch (status) {
      case UploaderStatus.Initializing:
        if (videoId) {
          setStatus(UploaderStatus.LoadingExistingVideo);
        } else if (props.videoId) {
          setVideoId(props.videoId);
          setStatus(UploaderStatus.LoadingExistingVideo);
        }
        break;
      case UploaderStatus.LoadingExistingVideo:
        if (!video) {
          return;
        }
        if (!isVideoStatusCompleted) {
          setStatus(UploaderStatus.Transcoding);
        } else if (isCreatingFromTemplate) {
          setStatus(UploaderStatus.Transcoding);
          cloneVideo({ programId, videoId: video.id }).then(onTranscoding);
        } else {
          setStatus(UploaderStatus.Completed);
          refetch().then((v) => onSuccess(v.data as Video));
        }
        break;
      case UploaderStatus.Uploaded:
        if (video && !isVideoStatusCompleted) {
          onTranscoding(video);
          setStatus(UploaderStatus.Transcoding);
        }
        break;
      case UploaderStatus.Transcoding:
        if (video) {
          if (isVideoStatusCompleted) {
            setStatus(UploaderStatus.Completed);
            onSuccess({ ...video, sourceType: UPLOAD });
          } else if (video.status === 'error') {
            setStatus(UploaderStatus.Errored);
            setErrorMessage('Video processing failed. Please try again.');
          }
        }
        break;
      case UploaderStatus.CaptionHasUpdated:
        // keep pulling video until the video status is updated to submitted/progressing
        // then set the uploader status to transcoding
        if (!isVideoStatusCompleted) {
          setStatus(UploaderStatus.Transcoding);
        }
        break;
      case UploaderStatus.Completed:
        // Check if video status has transitioned from completed back to submitted.
        // This can happen if a video caption upload transitions a video to processing,
        // and react-query returns a cached 'completed' video response just before
        // it returns the current state of the video.
        if (video?.status === 'submitted') {
          setStatus(UploaderStatus.Transcoding);
        }
        break;
      default:
        break;
    }
  }, [
    onTranscoding,
    onSuccess,
    isVideoStatusCompleted,
    props.videoId,
    status,
    video,
    videoId,
    refetch,
    isCreatingFromTemplate,
    programId,
  ]);

  function upload(file: File) {
    setStatus(UploaderStatus.Uploading);
    setVideoId(undefined);
    setErrorMessage(undefined);
    mutate({ programId, file, onUploadProgress, isDesignAsset });
  }

  function replace(currentVideoId: number, file: File) {
    setStatus(UploaderStatus.Uploading);
    setVideoId(currentVideoId);
    setErrorMessage(undefined);
    mutate({ programId, file, videoId, onUploadProgress, isDesignAsset });
  }

  function onCaptionUpdated() {
    setIsUploadingCaptions(UploaderStatus.CaptionHasUpdated === 6);
    setStatus(UploaderStatus.CaptionHasUpdated);
  }

  const ensureVideoId = React.useCallback(
    (newVideoId: number | undefined) => {
      if (newVideoId === videoId) {
        return;
      }
      setVideoId(newVideoId);
      setStatus(UploaderStatus.Initializing);
    },
    [videoId]
  );

  return {
    upload,
    replace,
    uploadedBytes: uploadProgress?.loaded,
    totalBytes: uploadProgress?.total,
    isUploading: isSaving,
    isTranscoding: status === UploaderStatus.Transcoding,
    isCompleted: status === UploaderStatus.Completed,
    isErrored: status === UploaderStatus.Errored,
    isCaptionUpdated: isUploadingCaptions,
    transcodeJobPercentComplete: video?.jobPercentComplete,
    errorMessage,
    onCaptionUpdated,
    ensureVideoId,
  };
}
