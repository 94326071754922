import React from 'react';
import { Box, ClickDropdown, Popover } from 'DesignSystem/Components';
import { Icon } from 'shared/Icon';
import { Button } from 'DesignSystem/Form';
import { DateTime } from 'luxon';
import { Time } from 'shared/DateInput/Time';

export type TimeSelectProps = {
  date?: DateTime;
  placeholder?: string;
  onChange: (changedTime?: DateTime) => void;
};

export const TimeSelect = React.memo((props: TimeSelectProps) => {
  const { date, placeholder, onChange } = props;

  return (
    <ClickDropdown
      dropdownRenderProp={() => (
        <Box width={250} style={{ cursor: 'auto', position: 'relative' }}>
          <Popover centered padding={0}>
            <Time date={date || DateTime.now()} onChange={onChange} />
          </Popover>
        </Box>
      )}
    >
      <Button
        input
        block
        badge={<Icon iconName="clock" />}
        label={date?.toFormat('t') || placeholder || 'HH:MM'}
      />
    </ClickDropdown>
  );
});
