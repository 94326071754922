import React, { useMemo } from 'react';
import {
  arc,
  AxisScale,
  interpolateSpectral,
  pie,
  quantize,
  ScaleOrdinal,
  scaleOrdinal,
} from 'd3';
import { ChartDimensions } from './Util';
import { NoDataChart } from './NoDataChart';

export type PieChartData = {
  label: string;
  value: number;
};

export type PieChartDimensions = ChartDimensions & {
  pie: {
    height: number;
    width: number;
    padding: {
      top: number;
      bottom: number;
      left: number;
      right: number;
    };
    gap: number;
  };
};

export type CustomDrawingFunction = (
  data: Array<PieChartData>,
  chartDimensions: PieChartDimensions,
  scaleX?: AxisScale<string> | AxisScale<number>,
  scaleY?: AxisScale<string> | AxisScale<number>,
  colorScale?: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>
) => React.ReactFragment;

interface IPieChart {
  showLabels?: boolean;
  data: Array<Partial<PieChartData>>;
  customColors?: ScaleOrdinal<string, unknown>;
  customDrawing?: CustomDrawingFunction;
  chartDimensions?: PieChartDimensions;
}

const isValidPieChartData = (d: unknown): d is PieChartData => {
  return (
    Object.prototype.hasOwnProperty.call(d, 'label') &&
    Object.prototype.hasOwnProperty.call(d, 'value') &&
    (d as PieChartData).value !== undefined
  );
};

export const PieChart: React.FC<IPieChart> = ({
  showLabels = true,
  data,
  customColors,
  customDrawing,
  chartDimensions = {
    height: 480,
    width: 640,
    padding: {
      left: 40,
      right: 40,
      top: 40,
      bottom: 40,
    },
    pie: {
      height: 480,
      width: 480,
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      gap: 0,
    },
  },
}) => {
  const { height, width, pie: pieDimensions } = chartDimensions;
  const filteredData: Array<PieChartData> = useMemo(
    (): Array<PieChartData> => data.filter(isValidPieChartData),
    [data]
  );
  const total = filteredData.reduce(
    (sum, chartData) => sum + chartData.value,
    0
  );

  const radius =
    Math.min(
      pieDimensions.width -
        pieDimensions.padding.left -
        pieDimensions.padding.right,
      pieDimensions.height -
        pieDimensions.padding.top -
        pieDimensions.padding.bottom
    ) / 2;

  const drawPie = pie<PieChartData>().value((d) => d.value);
  const drawArc = arc().padAngle(pieDimensions.gap);

  const colors =
    customColors ??
    scaleOrdinal()
      .domain(filteredData.map((d) => d.label))
      .range(
        quantize(
          (t) => interpolateSpectral(t * 0.8 + 0.1),
          filteredData.length
        ).reverse()
      );

  const drawCustom = useMemo(() => {
    return customDrawing?.(filteredData, chartDimensions) ?? <></>;
  }, [chartDimensions, customDrawing, filteredData]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderPie = (pieData: Array<PieChartData>) => {
    return (
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        {drawPie(pieData).map((d, i) => {
          const sliceInfo = {
            innerRadius: radius * 0.7,
            outerRadius: radius,
            startAngle: d.startAngle,
            endAngle: d.endAngle,
          };
          const centroid = drawArc.centroid(sliceInfo);

          const inflexionInfo = {
            innerRadius: radius + 20,
            outerRadius: radius + 20,
            startAngle: d.startAngle,
            endAngle: d.endAngle,
          };
          const inflexionPoint = drawArc.centroid(inflexionInfo);

          const LABEL_PADDING = 15;
          const isRightLabel = inflexionPoint[0] > 0;
          const labelPosX =
            inflexionPoint[0] + LABEL_PADDING * (isRightLabel ? 1 : -1);
          const textAnchor = isRightLabel ? 'start' : 'end';
          const { label } = d.data;
          const color = `${colors(i as never)}` || '#ABABAB';

          return (
            <React.Fragment key={label}>
              <path d={drawArc(sliceInfo) ?? ''} fill={color} key={d.value} />
              {showLabels && (
                <>
                  <circle cx={centroid[0]} cy={centroid[1]} r={2} />
                  <line
                    x1={centroid[0]}
                    y1={centroid[1]}
                    x2={inflexionPoint[0]}
                    y2={inflexionPoint[1]}
                    stroke="black"
                    fill="black"
                  />
                  <line
                    x1={inflexionPoint[0]}
                    y1={inflexionPoint[1]}
                    x2={labelPosX}
                    y2={inflexionPoint[1]}
                    stroke="black"
                    fill="black"
                  />
                  <text
                    x={labelPosX + (isRightLabel ? 2 : -2)}
                    y={inflexionPoint[1]}
                    textAnchor={textAnchor}
                    dominantBaseline="middle"
                    fontSize={14}
                  >
                    {Number(d.value / total).toLocaleString(undefined, {
                      style: 'percent',
                      minimumFractionDigits: 2,
                    })}{' '}
                    {label}
                  </text>
                </>
              )}
            </React.Fragment>
          );
        })}
      </g>
    );
  };

  return filteredData.length === 0 ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg viewBox={`0 0 ${width} ${height}`}>
      {renderPie(filteredData)}
      {drawCustom}
    </svg>
  );
};
