import React from 'react';
import * as Text from 'DesignSystem/Typography';
import ParseError from 'srt-validator/dist/utils/parse-error';
import { CaptionsEditor } from './CaptionsEditor';
import { CaptionsDropzone } from './CaptionsDropzone';
import { CaptionValidationErrors } from './CaptionsValidationErrors';

type CaptionFormProps = {
  captionText?: string;
  validationErrors: ParseError[] | undefined;
  onReplaceClick: () => void;
  onRemoveClick: () => void;
  onUploadClick: () => void;
  fileInput: React.ReactNode;
  errorMessage?: string;
  disabled?: boolean;
};

export const UploadedCaptionsForm: React.FC<CaptionFormProps> = ({
  captionText,
  validationErrors,
  onReplaceClick,
  onRemoveClick,
  onUploadClick,
  fileInput,
  errorMessage,
}) => {
  return (
    <>
      {captionText ? (
        <CaptionsEditor
          captionText={captionText}
          validationErrors={validationErrors}
          onReplaceClick={onReplaceClick}
          onRemoveClick={onRemoveClick}
        />
      ) : (
        <CaptionsDropzone onUploadClick={onUploadClick} />
      )}
      <CaptionValidationErrors validationErrors={validationErrors} />
      {errorMessage && (
        <Text.Body color={Text.color.redFull}>{errorMessage}</Text.Body>
      )}
      {fileInput}
    </>
  );
};
