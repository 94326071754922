import React from 'react';
import { BoxIntegrationFieldData } from 'models/publisher/block';
import {
  Button,
  FieldInput,
  Input,
  Textarea,
} from 'DesignSystem/Form/InputElements';
import * as Text from 'DesignSystem/Typography';
import { PickerModal } from 'shared/Box/PickerModal';
import { FileDropZone } from 'shared/FileDropZone';
import { Image } from 'shared/Image';
import { useToggle } from 'hooks/useToggle';
import { useContentImageUploader } from 'hooks/useContentImage';
import { FieldFormProps } from '../../useFieldForm';
import { Panel } from '../shared/Panel';
import { FieldLegend } from '../shared/FieldLegend';
import { useBoxIntegrationDataMutator } from './hooks/useBoxIntegrationDataMutator';
import styles from './box.module.css';
import { useKeepOpen } from '../shared/KeepOpen';
import { ExitBlocker } from '../Image/components/ExitBlocker';

export const BoxIntegration: React.FC<FieldFormProps<
  BoxIntegrationFieldData
>> = (props) => {
  const {
    data: boxData,
    updateBoxResource,
    updateResourceName,
    updateDescription,
    updateCallToAction,
    setImage,
    setImageAltText,
  } = useBoxIntegrationDataMutator(props);

  const [showPicker, setShowPicker] = React.useState(false);

  const resourceSelected = React.useCallback(() => {
    return boxData?.resource_id && boxData.resource_id !== 'BOX-RESOURCE-ID';
  }, [boxData]);

  const onModalSubmit = React.useCallback(
    (resource) => {
      updateBoxResource(resource);
      setShowPicker(false);
    },
    [updateBoxResource]
  );

  const isDragging = useToggle();

  const { uploadFile, isUploading } = useContentImageUploader({
    onUpload: setImage,
  });

  const setImageUpload = React.useCallback(
    (data: File) => {
      uploadFile(data);
    },
    [uploadFile]
  );

  useKeepOpen(ExitBlocker, isUploading);

  return (
    <>
      <Panel
        title="Box Integration"
        description="Integrate content from Box Knowledge Management"
      >
        {boxData && resourceSelected() && (
          <>
            <FieldInput legend={<FieldLegend>Resource Type</FieldLegend>}>
              <Text.Caption>
                {boxData.resource_type?.toUpperCase()}
              </Text.Caption>
            </FieldInput>
            <FieldInput
              legend={<FieldLegend>Name Override</FieldLegend>}
              htmlFor="resource-name"
            >
              <Input
                id="resource-name"
                value={boxData.name}
                placeholder="Optional"
                onChange={updateResourceName}
                className={styles.BoxInput}
              />
            </FieldInput>
          </>
        )}
        <Button
          className={styles.SelectButton}
          onClick={() => setShowPicker(true)}
          type="button"
          label={resourceSelected() ? 'Replace' : 'Select'}
        />
        {boxData && (
          <FieldInput
            htmlFor="box-edit-description"
            legend={<FieldLegend>Description</FieldLegend>}
          >
            <Textarea
              id="box-edit-description"
              value={boxData.description}
              onChange={updateDescription}
              className={styles.BoxInput}
            />
          </FieldInput>
        )}
        {boxData && (
          <FieldInput
            htmlFor="box-edit-button"
            legend={<FieldLegend>Button</FieldLegend>}
          >
            <Input
              id="box-edit-button"
              value={boxData.call_to_action}
              onChange={updateCallToAction}
              maxLength={100}
              autoComplete="off"
              className={styles.BoxInput}
            />
          </FieldInput>
        )}
        {boxData && (
          <FieldInput
            htmlFor="box-edit-image"
            legend={<FieldLegend>Image</FieldLegend>}
          >
            <div style={{ position: 'relative' }}>
              <FileDropZone
                accept="image/*"
                onFileSelect={setImageUpload}
                shouldClear={!boxData.image.url}
                onDragEnd={isDragging.disable}
                onDragStart={isDragging.enable}
              >
                <div style={{ margin: ' -14px -20px' }}>
                  <Image
                    width="275px"
                    height="137px"
                    isUploading={isUploading}
                    url={boxData.image.url}
                    placeholderIconName="ImagePlaceholder"
                  />
                </div>
              </FileDropZone>
            </div>
          </FieldInput>
        )}
        {boxData && (
          <FieldInput
            htmlFor="box-edit-alt"
            legend={<FieldLegend>Alt Text</FieldLegend>}
          >
            <Input
              id="box-edit-alt"
              value={boxData.image?.altText || ''}
              placeholder="Alt Text"
              onChange={setImageAltText}
              autoComplete="off"
              block
            />
          </FieldInput>
        )}
      </Panel>
      {showPicker && (
        <PickerModal
          description="Select the file or folder content to link in the campaign"
          type={['file', 'folder']}
          onSubmit={onModalSubmit}
          onCancel={() => setShowPicker(false)}
        />
      )}
    </>
  );
};
