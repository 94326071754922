import React from 'react';
import { useVideoCaptionValidator } from 'hooks/useVideoCaptionValidator';
import srtValidator from 'srt-validator';
import ParseError from 'srt-validator/dist/utils/parse-error';
import { FetchedCaption } from 'services/api-captions';

const UpdatedErrorMessages = {
  validatorErrorStartTime: {
    old: 'start time should be less than end time',
    new: 'Start time of subtitle occurs after its end time',
  },
  validatorErrorEndTime: {
    old: 'start time should be less than previous end time',
    new: 'Start time of subtitle occurs after its previous end time',
  },
  validatorErrorSequenceNumberStart: {
    old: 'number of sequence need to start with',
    new: 'Subtitle sequence should begin with',
  },
  validatorErrorSequenceNumberIncrement: {
    old: 'number of sequence need to increment by',
    new: 'Subtitle sequence should increment by',
  },
  parserErrorMissingText: {
    old: 'Missing caption text',
    new: 'Caption text is missing',
  },
  parserErrorMissingSequenceNumber: {
    old: 'Missing sequence number',
    new: 'Sequence number is missing',
  },
  parserErrorInvalidSequenceNumber: {
    old: 'Expected Integer for sequence number:',
    new: 'Expected sequence number instead of',
  },
  parserErrorMissingTimeSpan: {
    old: 'Missing time span',
    new: 'Time span is missing',
  },
  parserErrorInvalidTimeSpan: {
    old: 'Invalid time span:',
    new: 'Time span is invalid:',
  },
  parserErrorInvalidTimeStamp: {
    old: 'Invalid time stamp:',
    new: 'Time stamp is invalid:',
  },
};

export const useCaptionSettings: ({
  currentCaption,
  onFileSelect,
  onCaptionRemove,
  isCaptionsUploading,
}: {
  currentCaption?: FetchedCaption;
  onFileSelect: (file: File) => void;
  onCaptionRemove: (url: string) => void;
  isCaptionsUploading: boolean;
}) => {
  onFormSubmit: () => void;
  captionText?: string;
  onReplaceClick: () => void;
  onRemoveClick: () => void;
  onUploadClick: () => void;
  fileInput: React.ReactNode;
  errorMessage?: string;
  isDisabled: boolean;
  validationErrors?: ParseError[];
} = ({
  currentCaption,
  onFileSelect,
  onCaptionRemove,
  isCaptionsUploading,
}) => {
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const onUploadClick = () => inputFileRef.current?.click();
  const [shouldValidate, setShouldValidate] = React.useState(false);
  const [captionText, setCaptionText] = React.useState<string | undefined>(
    currentCaption?.text
  );

  const [toRemove, setToRemove] = React.useState<string | undefined>(undefined);
  const [toAdd, setToAdd] = React.useState<File | undefined>(undefined);

  const onReplaceClick = () => {
    if (currentCaption) setToRemove(currentCaption.url);
    onUploadClick();
  };

  const onRemoveClick = () => {
    if (currentCaption) setToRemove(currentCaption.url);
    setToAdd(undefined);
    setCaptionText(undefined);
  };

  const { validate } = useVideoCaptionValidator();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  function isSrt(file: File) {
    if (file.type === 'application/x-subrip') return true;
    if (file.name.toLowerCase().endsWith('.srt')) return true;
    return false;
  }

  const onInputChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage(undefined);

      const file = event.currentTarget.files?.[0];
      if (file) {
        const { isValid, errors } = validate({ file });
        if (isValid) {
          setShouldValidate(isSrt(file));

          setToAdd(file);
          setCaptionText(await file.text());
          return;
        }

        if (errors.length) {
          setErrorMessage(`${file.name}: ${errors.join(', ')}`);
        }
      }
      if (inputFileRef.current) inputFileRef.current.value = '';
    },
    [validate]
  );

  const onFormSubmit = () => {
    if (toAdd) onFileSelect(toAdd);
    if (toRemove) onCaptionRemove(toRemove);
  };

  function updateErrorMessages(errors: ParseError[]) {
    const result = [] as ParseError[];
    errors.forEach((msg) => {
      const updatedMessage = { ...msg };
      if (UpdatedErrorMessages[msg.errorCode]) {
        updatedMessage.message = msg.message.replace(
          UpdatedErrorMessages[msg.errorCode].old,
          UpdatedErrorMessages[msg.errorCode].new
        );
      }
      result.push(updatedMessage as ParseError);
    });
    return result;
  }

  const validationErrors = React.useMemo(() => {
    if (captionText && shouldValidate) {
      // according to this comment https://firstup-io.atlassian.net/browse/FE-1143?focusedCommentId=530572
      // we should support both 00:00:00.000 and 00:00:00,000 formats
      const newCaptionText = captionText.replace(
        /(\d{2}:\d{2}:\d{2})\.(\d{3})/g,
        '$1,$2'
      );
      const result = srtValidator(newCaptionText || '') as ParseError[];
      return updateErrorMessages(result);
    }
    return undefined;
  }, [captionText, shouldValidate]);

  const fileInput = React.useMemo(
    () => (
      <input
        ref={inputFileRef}
        type="file"
        style={{ display: 'none' }}
        accept="application/x-subrip,.vtt,.srt"
        disabled={isCaptionsUploading}
        onChange={onInputChange}
      />
    ),
    [isCaptionsUploading, onInputChange]
  );

  const isDisabled = React.useMemo(() => {
    return (
      (!toAdd && !toRemove) ||
      (validationErrors !== undefined && validationErrors.length > 0)
    );
  }, [toAdd, toRemove, validationErrors]);

  return {
    onFormSubmit,
    captionText,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    validationErrors,
    isDisabled,
  };
};
