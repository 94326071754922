import { Initiative } from 'models/initiative';
import { SearchMetaTag } from 'models/search-meta-tag';
import { Topic } from 'models/topic';
import { DateTime } from 'luxon';
import { Author, BaseAuthor, defaultAuthor } from 'models/author';
import { Audience } from 'models/audience';
import type { CustomSlug } from 'models/custom-slug';
import { defaultFirstNotification, Notification } from 'models/notification';
import { Duration } from 'models/duration';
import { DeliveryChannels } from 'models/channel';
import { DeliveryType } from 'models/delivery-type';
import { Priority } from 'models/priority';
import { DisplaySettings } from '../display-settings';

export const OPTIMIZE = 'optimize';
export const HYBRID = 'hybrid';
export const OVERRIDE = 'override';
export const MIN_RETRY_INTERVALS = {
  NICE: 10,
  SHOULD: 5,
  MUST: 2,
  CRITICAL: 1,
};
export const SHAREABLE_MESSAGE_MAX_LENGTH = 280;
export const SLUG_MAX_LENGTH = 1024;
export const CUSTOM_TOPIC_LABEL_MAX_LENGTH = 40;

export type Settings = {
  priority?: Priority;

  publishedAt?: DateTime;
  archiveAt?: DateTime;
  duration?: Duration;

  deliveryType?: DeliveryType;
  retries: number;
  isCommentable: boolean;
  isTranslatable: boolean;
  isFeatured: boolean;
  isShareable: boolean;
  isResource: boolean;
  acknowledge: boolean;
  shareMessage: string;
  slug: string;
  contentLabel?: Label;
  acknowledgementLabel?: Label;
  emailSenderAlias?: EmailSenderAlias;
  displaySettings: DisplaySettings;
  includeInForYou: boolean;
  initiativeTags: Initiative[];
  searchMetaTags?: SearchMetaTag[];
  contentAuthor: BaseAuthor | Author;

  contentTopics: Topic[];
  audiences: Audience[];
  audiencesCopy?: Audience[];
  limitVisibilityToAudience: boolean;
  deliveryChannels: DeliveryChannels;

  notifications: Notification[];

  overrideIntelligence: boolean;

  notifDeliveryTimesEnabled: boolean;
  publicationState?: string;

  deliveryPageVersion?: number;

  optimizedDeliveryEnabled?: boolean;

  customSlugs?: CustomSlug[];
};

export const defaultDisplaySettings: DisplaySettings = {
  displayInternalContent: true,
  messageCardColor: '',
};

export type EmailSenderAlias = {
  id: string;
  senderName: string;
  senderEmail: string;
};

export type Label = { preset?: string; text: string };

export const FEATURED_LABELS = [
  { preset: 'default', text: 'Featured' },
  { preset: 'employee_spotlight', text: 'Employee Spotlight' },
  { preset: 'from_the_ceo', text: 'From the CEO' },
  { preset: 'feedback', text: 'Give Us Your Feedback' },
  { preset: 'announcement', text: 'Important Announcement' },
  { preset: 'learn_more', text: 'Learn More' },
  { preset: 'needs_attention', text: 'Needs Your Attention' },
  { preset: 'product_announcement', text: 'Product Announcement' },
  { preset: 'share_today', text: 'Share Today' },
];

export const ACKNOWLEDGEMENT_LABELS = [
  { preset: 'default', text: 'I acknowledge' },
  { preset: 'read', text: 'I have read and accept' },
  { preset: 'accept', text: 'I accept' },
  { preset: 'watched', text: 'I have watched and accept' },
];

export const defaultSettings: Settings = {
  publishedAt: undefined,
  archiveAt: undefined,
  retries: 0,
  includeInForYou: true,
  contentAuthor: defaultAuthor,
  contentTopics: [],
  audiences: [],
  audiencesCopy: [],
  limitVisibilityToAudience: false,
  initiativeTags: [],
  searchMetaTags: [],
  isCommentable: false,
  isFeatured: false,
  isShareable: false,
  isTranslatable: false,
  isResource: false,
  acknowledge: false,
  shareMessage: '',
  slug: '',
  displaySettings: defaultDisplaySettings,
  notifDeliveryTimesEnabled: false,
  deliveryChannels: {
    assistant: true,
    email: false,
    feed: false,
    push: false,
  },

  notifications: [defaultFirstNotification],
  deliveryPageVersion: 1,
  overrideIntelligence: false,
  optimizedDeliveryEnabled: undefined,
  customSlugs: [],
};

export const ACKNOWLEDGEMENT_LABEL_MAX_LENGTH = 40;

export const hasAudience = ({ audiences }: Settings): boolean => {
  return audiences?.length > 0;
};

export const hasTopic = ({ contentTopics }: Settings): boolean => {
  return contentTopics?.length > 0;
};

export const computeMaxRetries = (
  { deliveryType, notifDeliveryTimesEnabled, notifications, retries }: Settings,
  maxRetries: number
): number => {
  if (deliveryType === OVERRIDE || retries === 0) {
    return 0;
  }
  return notifDeliveryTimesEnabled ? notifications.length - 1 : maxRetries;
};

export const hasRetargettingNotif = ({ notifications }: Settings): boolean => {
  return notifications.length > 1;
};

export const engagementBoostOff = ({ retries }: Settings): boolean => {
  return retries === 0;
};
