import React from 'react';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex } from 'DesignSystem/Layout/Flex';
import { WarningTriangle } from 'shared/icons';
import * as Text from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';

export const CaptionJobs: React.FC = () => {
  const {
    transcriptionJob,
    isCaptionJobsLoading,
    isCaptionTranscriptionEnabled,
    isLoadingFeatureFlags,
  } = useCaptionsJobs();

  if (
    !isCaptionTranscriptionEnabled ||
    isLoadingFeatureFlags ||
    (isCaptionTranscriptionEnabled && !transcriptionJob) ||
    (isCaptionTranscriptionEnabled &&
      transcriptionJob &&
      transcriptionJob.status === 'completed')
  ) {
    return null;
  }

  return (
    <>
      {isCaptionJobsLoading && <LoadingSpinner size="small" />}
      <Box
        radius={4}
        margin={[8, 0, 0, 0]}
        padding={[12, 16]}
        color={Text.background.gray05}
      >
        <Flex start>
          {transcriptionJob && transcriptionJob.status === 'processing' && (
            <>
              <LoadingSpinner size="xsmall" />
              <Box padding={[0, 0, 0, 8]}>
                <Text.Caption color={Text.color.gray90}>
                  Transcribing...
                </Text.Caption>
              </Box>
            </>
          )}
        </Flex>

        {transcriptionJob && transcriptionJob.status === 'failed' && (
          <Flex start>
            <WarningTriangle />
            <Box padding={[0, 0, 0, 8]}>
              <Text.Caption color={Text.color.redFull}>
                Transcription failed.
              </Text.Caption>
            </Box>
          </Flex>
        )}
      </Box>
    </>
  );
};
