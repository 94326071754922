import React, { useMemo } from 'react';
import { useLibraryFontsCollection } from 'hooks/use-library-fonts-collection';
import { DesignContext, useDesignContext } from 'contexts/design';
import { useDesignHooks } from 'components/publisher/theme/Design/useDesignHooks';
import { PublisherType } from 'models/library';
import { DesignData } from 'services/api-design';
import { DesignComponent } from 'components/publisher/theme/Design/Component';
import { Design } from 'components/publisher/pages/Design';
import { useJourneyState } from 'contexts/journey';
import { usePortal } from 'hooks/usePortal';
import { createPortal } from 'react-dom';
import { useProgram } from 'contexts/program';
import { useDesign } from 'hooks/design';
import { NavigationBars } from './NavigationBars';
import { useJourneyContentDesigner } from './JourneyContentDesignProvider';
import styles from './styles.module.css';
import { ContentListState } from '../JourneyContentListDrawer/Drawer';

export const JourneyContentDesignerComponent: React.FC = () => {
  const portal = usePortal('journey-content-designer');
  const { data: fonts } = useLibraryFontsCollection();
  const {
    isDesignerOpen,
    closeDesigner,
    onSelectDesign,
  } = useJourneyContentDesigner();
  const { activeStep, setContentListState } = useJourneyState();

  const fontOptions = useMemo(() => {
    return fonts
      .filter((i) => i.is_enabled_for_program)
      .map((i) => {
        return {
          label: i.title,
          value: i.asset.value,
          id: i.id,
          url: i.asset.css?.url,
          is_enabled_for_program: i.is_enabled_for_program,
        };
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [fonts]);

  const designContext = useDesignContext();
  const designEditor = useDesignHooks({
    design: designContext.design,
    fontOptions,
    update: designContext.update,
    publisherType: PublisherType.topicPages,
  });

  const onSaveSuccess = React.useCallback(
    (design?: DesignData) => {
      if (design?.attributes) {
        onSelectDesign(design.attributes);
      }
    },
    [onSelectDesign]
  );

  const saveAndClose = React.useCallback(() => {
    designContext.save({ onSuccess: onSaveSuccess });
    closeDesigner();
    setContentListState(ContentListState.Closed);
  }, [closeDesigner, designContext, onSaveSuccess, setContentListState]);

  const navigationComponent = (
    <NavigationBars
      actionName="Save"
      canPerformAction
      exitHandler={closeDesigner}
      action={saveAndClose}
    />
  );

  if (
    !isDesignerOpen ||
    !activeStep ||
    activeStep?.type !== 'communication' ||
    !portal.ready
  )
    return null;

  return createPortal(
    <div className={styles.journeyDesignEditorModal}>
      <DesignComponent
        path="/"
        designPermission={{ canEdit: true, errors: [], isLoading: false }}
        designEditor={designEditor}
        navigationComponent={navigationComponent}
        omitMenuItems={['preview', 'card', 'personalizedFields']}
      >
        <Design implementation="new" />
      </DesignComponent>
    </div>,
    portal.target
  );
};

export const JourneyContentDesigner: React.FC = () => {
  const { id: programId } = useProgram();
  const { activeStep } = useJourneyState();
  const { designerId: designId } = useJourneyContentDesigner();
  const designContext = useDesign(programId, designId || 'new', false);
  if (!activeStep || activeStep.type !== 'communication') return null;

  return (
    <DesignContext.Provider value={designContext}>
      {designContext.status.hasLoaded && <JourneyContentDesignerComponent />}
    </DesignContext.Provider>
  );
};
