import { useDesignContext } from 'contexts/design';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useCaptionsJobsQuery } from 'hooks/video';
import { VideoFieldData } from 'models/donkey';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import {
  TranscribeProps,
  transcribeVideo,
  TranscriptionJob,
} from 'services/api-captions';

type CaptionJobsContextProps = {
  isCaptionJobsLoading: boolean;
  setTranscriptionJob: (job: TranscriptionJob) => void;
  translationJobs?: [];
  transcriptionJob?: TranscriptionJob;
  // going to return isLoading for both of the feature flags here
  isLoadingFeatureFlags: boolean;
  isCaptionTranscriptionEnabled: boolean;
};

const CaptionJobsContext = createContext<CaptionJobsContextProps>({
  isCaptionJobsLoading: false,
  setTranscriptionJob: () => {},
  translationJobs: [],
  transcriptionJob: undefined,
  isCaptionTranscriptionEnabled: false,
  isLoadingFeatureFlags: false,
});

export const CaptionJobsProvider: React.FC<{ fieldData: VideoFieldData }> = ({
  fieldData,
  children,
}) => {
  const { id: programId } = useProgram();
  const [transcriptionJob, setTranscriptionJob] = useState<
    TranscriptionJob | undefined
  >();
  const { data, isLoading: isLoadingFeatureFlags } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CaptionTranscription'
  );

  const isCaptionTranscriptionEnabled = !!data?.value;

  const { active: isDesignAsset } = useDesignContext();
  const {
    data: captionJobs,
    isLoading: isCaptionJobsLoading,
  } = useCaptionsJobsQuery({
    videoId: fieldData.video_id,
    programId,
    isDesignAsset,
    enabled: isCaptionTranscriptionEnabled,
    refetchInterval:
      transcriptionJob?.status === 'processing' ? 3000 : undefined,
  });

  useEffect(() => {
    if (captionJobs?.transcription) {
      setTranscriptionJob(captionJobs.transcription);
    }
  }, [captionJobs?.transcription]);

  return (
    <CaptionJobsContext.Provider
      value={{
        transcriptionJob,
        isCaptionJobsLoading,
        translationJobs: captionJobs?.translations,
        setTranscriptionJob,
        isLoadingFeatureFlags,
        isCaptionTranscriptionEnabled,
      }}
    >
      {children}
    </CaptionJobsContext.Provider>
  );
};

export const useCaptionsJobs: () => CaptionJobsContextProps = () => {
  const context = useContext(CaptionJobsContext);
  if (context === undefined) {
    throw new Error(
      'useCaptionsJobs must be used within a CaptionJobsProvider'
    );
  }
  return context;
};

type UseTranscribeVideoProps = {
  onSuccess: (data: TranscriptionJob) => void;
};

type UseTranscribeVideoReturn = {
  startTranscription: (props: TranscribeProps) => void;
};

export const useTranscribeVideo = ({
  onSuccess,
}: UseTranscribeVideoProps): UseTranscribeVideoReturn => {
  const { mutate: startTranscription } = useMutation<
    TranscriptionJob,
    Error,
    TranscribeProps
  >(transcribeVideo, {
    onSuccess,
  });
  return { startTranscription };
};
