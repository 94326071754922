import * as React from 'react';
import cx from 'classnames';
import { useNavigate, useLocation } from '@reach/router';
import { useBulkSelect } from 'contexts/bulk-select';
import { Checkbox } from 'DesignSystem/Form';
import { Modal } from 'shared/Modal';
import { Tooltip } from 'DesignSystem/Components/Tooltip';
import styles from '../banner-list-item.module.css';
import { Thumbnail } from '../../Thumbnail';
import {
  HoverIconMenu,
  ItemType as MenuItemType,
} from '../../hover-dropdown/HoverIconMenu';

export type ThumbnailType = React.ComponentProps<typeof Thumbnail>;
// This is defined in the CSS.  The height of the thumbnail is 100.  The padding is 15 both above
// and below.  The margin below is 10.
export const DESIGN_SYSTEM_ROW_HEIGHT = 100;
export type MenuItemsType = Array<MenuItemType>;

export type BannerProps = {
  thumbnail?: ThumbnailType;
  menuItems: MenuItemsType;
  rowId?: string;
  onSelectionChange?: (id: string, selected: boolean) => void;
};

export type EmptyBannerDeleteItem = {
  deleteAction: () => void;
  showingDeleteModal: boolean;
  toggleDeleteModal: () => void;
  resource: string;
};

type PropsType = {
  children?: React.ReactNode;
  menuFooter?: React.ReactNode;
  hideFirstMenuItem?: boolean;
  hideMenuDropdown?: boolean;
  disabledClick?: boolean;
  deleteItem?: EmptyBannerDeleteItem;
  deleteItemModal?: React.ReactNode;
  isSelectable?: boolean;
  notSelectableReason?: string;
  layout?: 'classic' | 'journeys';
  'aria-label'?: string;
} & BannerProps;
/* eslint-disable react/jsx-props-no-spreading */

export const EmptyBanner: React.FC<PropsType> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    thumbnail,
    menuItems,
    menuFooter,
    children,
    rowId,
    hideFirstMenuItem,
    disabledClick,
    deleteItem,
    hideMenuDropdown,
    deleteItemModal,
    isSelectable = true,
    notSelectableReason = 'This item is not selectable',
    layout = 'classic',
    'aria-label': ariaLabel,
  } = props;
  const items = hideFirstMenuItem ? menuItems.slice(1) : menuItems;
  const {
    bulkSelectEnabled,
    selectedCount,
    onSelectionChange,
    isListItemSelected,
  } = useBulkSelect();

  const selected = isListItemSelected(rowId);
  const handleItemClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      let busted = false;
      let el = (e.target as HTMLElement).parentElement;
      while (el) {
        if (
          el.classList.contains('hover-dropdown-target') ||
          el.dataset.identifier === 'deleteModal'
        ) {
          busted = true;
          break;
        }
        el = el.parentElement;
      }
      if (busted) return;
      e.stopPropagation();
      if (disabledClick) return;
      if (
        bulkSelectEnabled &&
        isSelectable &&
        selectedCount &&
        onSelectionChange &&
        rowId
      ) {
        onSelectionChange(rowId, !selected);
        return;
      }

      if (!items[0]) return;

      if (menuItems[0]?.onClick) {
        menuItems[0].onClick(
          (e as unknown) as React.MouseEvent<HTMLAnchorElement, MouseEvent>
        );
      } else if (menuItems[0]?.href) {
        const fromUrl = `${location.pathname}${
          location.search ? `${location.search}` : ''
        }`;
        navigate(menuItems[0].href, { state: { from: fromUrl } });
      }
    },
    [
      disabledClick,
      bulkSelectEnabled,
      selectedCount,
      onSelectionChange,
      rowId,
      items,
      menuItems,
      navigate,
      selected,
      isSelectable,
      location,
    ]
  );

  const displayMenuItems = () => {
    if (hideMenuDropdown) return null;
    return (
      items.length > 0 && (
        <div className={styles.menu}>
          <HoverIconMenu
            layout={layout}
            menuItems={items}
            footerComponent={menuFooter}
            dropdownClassName={cx(
              'dropdown-align-right',
              styles.hoverDropdown,
              { [styles.journeysLayout]: layout === 'journeys' }
            )}
            openDelay="click"
          >
            <button type="button" className={styles.button}>
              <span>•</span>
              <span>•</span>
              <span>•</span>
            </button>
          </HoverIconMenu>
        </div>
      )
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={cx(styles.empty, {
        [styles.checkboxBox]: bulkSelectEnabled,
        [styles.checkboxSelectable]: bulkSelectEnabled && isSelectable,
        [styles.selectedItem]: selected && isSelectable,
        [styles.clickable]: menuItems[0] && isSelectable,
      })}
      onClick={handleItemClick}
      role="button"
      aria-label={ariaLabel}
      tabIndex={0}
      data-test="banner-item"
    >
      <Tooltip
        content={notSelectableReason}
        disabled={isSelectable}
        placement="top-start"
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={(e) => e.stopPropagation()} className={styles.checkmark}>
          <Checkbox
            disabled={!isSelectable}
            onChange={(checked) => {
              if (rowId) onSelectionChange(rowId, checked);
            }}
            checked={isSelectable && selected}
          />
        </div>
      </Tooltip>

      {thumbnail && (
        <div className={styles.icon}>
          <Thumbnail {...thumbnail} size="large" />
        </div>
      )}
      <div className={styles.main}>
        {children}
        {displayMenuItems()}
      </div>

      {deleteItemModal &&
        deleteItem &&
        deleteItem.showingDeleteModal &&
        deleteItemModal}

      {!deleteItemModal && deleteItem && deleteItem.showingDeleteModal && (
        <Modal
          showModal={deleteItem.showingDeleteModal}
          title={`Delete ${deleteItem.resource}`}
          showTitle
          onClose={deleteItem.toggleDeleteModal}
          identifier="deleteModal"
        >
          <div className={styles.deleteWarning}>
            Are you sure you want to delete this {deleteItem.resource}?
          </div>
          <div className={styles.actionWrapper}>
            <button
              className={styles.actionButton}
              type="button"
              onClick={deleteItem.toggleDeleteModal}
            >
              Cancel
            </button>
            <button
              className={styles.actionButton}
              type="button"
              onClick={() => {
                deleteItem.toggleDeleteModal();
                deleteItem.deleteAction();
              }}
            >
              Confirm Delete
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
