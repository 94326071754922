import React from 'react';
import { Box } from 'DesignSystem/Components';
import { GenericSelect } from 'shared/Select';
import { OptionType } from 'hooks/common';
import { Body, Caption, color, Subheading } from 'DesignSystem/Typography';
import {
  ComplexExpression,
  defaultComplexExpression,
  SimpleExpression,
} from 'models/expression';
import { ScheduledTrigger, StartStep, Trigger } from 'models/journeys/journey';
import { Radio } from 'DesignSystem/Form';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { DateTime } from 'luxon';
import { CalendarSelect } from '../shared/CalendarSelect';
import { EventSelect, SUPPORTED_CAMPAIGN_EVENTS } from './EventSelect';
import { AudienceSelect } from '../shared/AudienceSelect';
import styles from './StartConfig.module.css';
import { TimeSelect } from '../shared/TimeSelect';
import { useJourneyValidationErrors } from '../../JourneyErrors/useJourneyValidationErrors';
import { TriggerSelect } from './TriggerSelect';

const TRIGGER_SELECT_MAP = {
  immediate: 'date',
  scheduled: 'date',
  event: 'event',
  recurring: 'recurring',
};

const startTypeOptions: OptionType[] = [
  { label: 'One-Time', value: 'date' },
  { label: 'Event', value: 'event' },
  { label: 'Daily', value: 'recurring' },
];

export const ReadOnlyStartConfig: React.FC<{ step: StartStep }> = ({
  step,
}) => {
  const { trigger, criterion } = step;
  const selectedType = trigger && TRIGGER_SELECT_MAP[trigger.type];
  const startLabel = startTypeOptions.find(
    (option) => option.value === selectedType
  );
  const expression =
    criterion ?? defaultComplexExpression(defaultComplexExpression());
  return (
    <Box padding={[0, 0, 32, 0]}>
      <Box>
        <Subheading bold>Start Type</Subheading>
        <Box style={{ textTransform: 'capitalize' }}>
          {startLabel?.label}
          {selectedType === 'date' ? <>, {trigger?.type}</> : null}
        </Box>
      </Box>

      {selectedType === 'date' && trigger?.type === 'scheduled' && (
        <Box>
          <Caption>
            {trigger.date?.toLocaleString(DateTime.DATETIME_MED)}
          </Caption>
        </Box>
      )}

      {selectedType === 'event' && trigger?.type === 'event' && (
        <Box margin={[16, 0, 0, 0]}>
          <Subheading bold>Event Name</Subheading>
          <Box>
            {
              SUPPORTED_CAMPAIGN_EVENTS[
                trigger.event as keyof typeof SUPPORTED_CAMPAIGN_EVENTS
              ]
            }
          </Box>
        </Box>
      )}

      {selectedType === 'recurring' && trigger?.type === 'recurring' && (
        <Box margin={[16, 0, 0, 0]}>
          <Subheading bold>Trigger</Subheading>
          <Box padding={[16, 0, 0, 0]} width="100%" maxWidth="1000px">
            <TriggerSelect expression={trigger.triggerCriterion} isDisabled />
          </Box>
        </Box>
      )}

      <Box padding={[16, 0, 32, 0]}>
        <Subheading bold>Filter Members</Subheading>
        <Box padding={[16, 0, 0, 0]}>
          <AudienceSelect
            canEdit={false}
            showCount={selectedType === 'date'}
            value={expression}
            menuPlacement="bottom"
            onChange={() => {}}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const StartConfig: React.FC<{
  step: StartStep;
  onUpdateStep: (step: StartStep) => void;
}> = ({ step, onUpdateStep }) => {
  const { resolveErrors } = useJourneyValidationErrors();

  const { trigger, criterion } = step;
  const [selectedType, setSelectedType] = React.useState<string | undefined>(
    trigger && TRIGGER_SELECT_MAP[trigger.type]
  );

  const expression =
    criterion ?? defaultComplexExpression(defaultComplexExpression());

  const getDefaultTrigger = (triggerType: string): Trigger | undefined => {
    switch (triggerType) {
      case 'immediate':
        return {
          type: 'immediate',
        };
      case 'scheduled':
        return {
          date: undefined,
          type: 'scheduled',
        };
      case 'event':
        return {
          type: 'event',
          event: '',
        };
      case 'recurring':
        return {
          type: 'recurring',
          frequency: 'daily',
        };
      default:
        return undefined;
    }
  };

  const onChangeStartType = React.useCallback(
    (newStartType) => {
      const newTrigger = getDefaultTrigger(newStartType);
      if (newTrigger) {
        if (newTrigger.type === 'scheduled') {
          const scheduledTrigger = newTrigger as ScheduledTrigger;
          if (!scheduledTrigger.date) {
            scheduledTrigger.date = DateTime.now().plus({ hour: 1 });
          }
        } else {
          resolveErrors(step.id, ['scheduledTime']);
        }
        resolveErrors(step.id, [
          'trigger',
          ...(newTrigger.type === 'recurring' ? [] : ['triggerCriterion']),
        ]);
      }
      onUpdateStep({ ...step, trigger: newTrigger });
    },
    [onUpdateStep, resolveErrors, step]
  );

  const onUpdateScheduleDate = (trig: ScheduledTrigger) => (
    date: DateTime | undefined
  ) => {
    if (date && date.diffNow().toMillis() > 0) {
      resolveErrors(step.id, ['scheduledTime']);
      onUpdateStep({
        ...step,
        trigger: { ...trig, date },
      });
    }
  };

  return (
    <>
      <Box padding={[0, 0, 32, 0]}>
        <Subheading bold block>
          Start type
        </Subheading>
        <Box width={300}>
          <GenericSelect
            value={selectedType}
            onChange={(newStartType) => {
              setSelectedType(newStartType);
              onChangeStartType(newStartType);
            }}
            options={startTypeOptions}
            menuPlacement="bottom"
            placeholder="Choose a start type"
            keepBlank
          />
          {selectedType === 'date' && !trigger?.type && (
            <Caption color={color.redFull}>
              An additional selection is required
            </Caption>
          )}
        </Box>
      </Box>
      {selectedType === 'date' && (
        <Box padding={[0, 0, 32, 0]}>
          <Box>
            <Flex start className={styles.dateRadioGroup}>
              <FlexItem start>
                <Radio
                  type="radio"
                  name="dateType"
                  className={styles.dateRadio}
                  checked={trigger?.type === 'immediate'}
                  onSelect={() => {
                    onChangeStartType('immediate');
                  }}
                />
              </FlexItem>
              <FlexItem>
                <Flex column alignStart>
                  <FlexItem>
                    <Body>Immediate</Body>
                  </FlexItem>
                  <FlexItem>
                    <Caption>
                      The journey will be active immediately after publishing
                    </Caption>
                  </FlexItem>
                </Flex>
              </FlexItem>
            </Flex>
            <Flex start className={styles.dateRadioGroup}>
              <FlexItem start>
                <Radio
                  type="radio"
                  name="dateType"
                  className={styles.dateRadio}
                  checked={trigger?.type === 'scheduled'}
                  onSelect={() => {
                    onChangeStartType('scheduled');
                  }}
                />
              </FlexItem>
              <FlexItem>
                <Flex column alignStart>
                  <FlexItem>
                    <Body>Scheduled</Body>
                  </FlexItem>
                  <FlexItem>
                    <Caption>
                      Choose a date and time for the journey to become active
                    </Caption>
                  </FlexItem>
                </Flex>
              </FlexItem>
            </Flex>

            {trigger?.type === 'scheduled' && (
              <Flex start>
                <FlexItem className={styles.dateTimeInput}>
                  <CalendarSelect
                    date={trigger.date}
                    placeholder="Choose date"
                    onChange={onUpdateScheduleDate(trigger)}
                  />
                </FlexItem>
                <FlexItem className={styles.dateTimeInput}>
                  <TimeSelect
                    date={trigger.date}
                    placeholder="Choose time"
                    onChange={onUpdateScheduleDate(trigger)}
                  />
                </FlexItem>
              </Flex>
            )}
          </Box>
        </Box>
      )}

      {selectedType === 'event' && trigger?.type === 'event' && (
        <Box padding={[0, 0, 32, 0]}>
          <Subheading bold>Event</Subheading>
          <Box width={300} padding={[12, 0, 0, 0]}>
            <EventSelect
              event={trigger.event}
              onChange={(event) => {
                if (event) {
                  onUpdateStep({
                    ...step,
                    trigger: { ...trigger, event },
                  });
                }
              }}
            />
          </Box>
        </Box>
      )}

      {selectedType === 'recurring' && trigger?.type === 'recurring' && (
        <Box padding={[0, 0, 32, 0]}>
          <Subheading bold>Trigger</Subheading>
          <Box padding={[16, 0, 0, 0]} width="100%" maxWidth="1000px">
            <TriggerSelect
              expression={trigger.triggerCriterion}
              onChange={(updatedExpression: SimpleExpression) => {
                onUpdateStep({
                  ...step,
                  trigger: {
                    ...trigger,
                    triggerCriterion: updatedExpression,
                  },
                });
                resolveErrors(step.id, ['triggerCriterion']);
              }}
            />
          </Box>
        </Box>
      )}

      {selectedType && (
        <Box padding={[0, 0, 32, 0]}>
          <Subheading bold>Filter Members</Subheading>
          <Box padding={[16, 0, 0, 0]}>
            <AudienceSelect
              showCount={selectedType === 'date'}
              value={expression}
              menuPlacement="bottom"
              onChange={(updatedExpression: ComplexExpression) => {
                onUpdateStep({
                  ...step,
                  criterion: updatedExpression,
                });
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
