import React from 'react';
import cx from 'classnames';

import { scaleOrdinal } from 'd3';
import { CampaignResultsData } from '../../../../../../../services/api-insights';
import {
  CustomDrawingFunction,
  HorizontalBarChart,
} from '../../../../../../../shared/Charts/HorizontalBarChart';
import styles from '../MetricsCards.module.css';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IContentEngagementActionCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const dimensions = {
  height: 285,
  width: 528,
  padding: {
    top: 0,
    bottom: 0,
    left: 110,
    right: 100,
  },
  bars: {
    padding: {
      inner: 0.5,
      outer: 0,
    },
  },
};

export const ContentEngagementActionCard: React.FC<IContentEngagementActionCard> = ({
  data,
  isLoading,
}) => {
  const read = data?.campaign.engagement.read || 0;
  const liked = data?.campaign.engagement.liked || 0;
  const commented = data?.campaign.engagement.commented || 0;
  const shared = data?.campaign.engagement.shared || 0;
  const bookmarked = data?.campaign.engagement.bookmarked || 0;
  const graphData = [
    {
      label: 'Read',
      value: read,
    },
    {
      label: 'Liked',
      value: liked,
    },
    {
      label: 'Commented',
      value: commented,
    },
    {
      label: 'Shared',
      value: shared,
    },
    {
      label: 'Bookmarked',
      value: bookmarked,
    },
  ];

  const color = scaleOrdinal()
    .domain(graphData.map((d) => d.label))
    .range([
      // 'rgba(255, 208, 130, 0.93)'
      'url(#orangeBarGradient)',
    ]);

  const drawLabels: CustomDrawingFunction = ({ bars, scaleX, scaleY }) => {
    if (bars === undefined) {
      return <></>;
    }

    return bars.map(({ label }) => {
      if (
        scaleX === undefined ||
        scaleX.bandwidth === undefined ||
        scaleY === undefined
      ) {
        return <></>;
      }
      const xPos = 8;
      const yPos = scaleX(label as never) ?? 0;

      return (
        <text
          key={`bar-${label}`}
          x={xPos}
          y={yPos + (scaleX.bandwidth() ?? 1) / 2}
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={15}
        >
          {label}
        </text>
      );
    });
  };

  const drawCustom: CustomDrawingFunction = ({ bars, scaleX, scaleY }) => {
    return (
      <>
        {drawLabels({ bars, scaleX, scaleY })}

        <defs>
          <linearGradient
            id="orangeBarGradient"
            x1="0%"
            x2="100%"
            y1="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(245, 158, 11, 1)" />
            <stop offset="100%" stopColor="rgba(255, 208, 130, 0.93)" />
          </linearGradient>
        </defs>
      </>
    );
  };

  return (
    <div className={cx(styles.metricCard, styles.metricCardHalf)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>
          What happened after Users opened your content in Experience?
        </h1>
      </div>
      {isLoading ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={dimensions} />
        </div>
      ) : (
        <div className={styles.metricCardBody}>
          <HorizontalBarChart
            data={graphData}
            customColors={color}
            chartDimensions={dimensions}
            customDrawing={drawCustom}
            hideXAxis
            hideYAxis
            units="User"
          />
        </div>
      )}
    </div>
  );
};
