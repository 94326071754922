import { MutationOptions, useMutation, UseMutationResult } from 'react-query';
import {
  BoxFolderData,
  FetchProps,
  fetchBoxFoldersPage,
  createBoxFolder,
  CreateBoxFolderData,
  createBoxFolderAudienceMapping,
} from 'services/api-box-mangement';
import { InfiniteQueryResponse, useInfiniteApiQuery } from './common';

export const BOX_MANAGEMENT_INFINITE_QUERY_KEY = 'box-management-infinite';

export const useBoxFoldersInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<BoxFolderData> => {
  const { programId } = props;
  const {
    isLoading,
    errorMessage,
    data,
    isFetchingNextPage,
    meta,
  } = useInfiniteApiQuery(
    [BOX_MANAGEMENT_INFINITE_QUERY_KEY, JSON.stringify(props)],
    fetchBoxFoldersPage,
    {
      programId,
    }
  );

  return {
    isLoading,
    errorMessage,
    isFetchingNextPage,
    fetchNextPage: () => {}, // Disable fetchNextPage since there's only one page
    hasNextPage: false, // Indicate that there's no next page
    data: data || [], // Return the first page's data
    meta,
  };
};

type CreateBoxFolderMutationResult = UseMutationResult<
  BoxFolderData,
  Error,
  CreateBoxFolderData
>;

export const useCreateBoxFolder = ({
  onSuccess,
  onError,
}: MutationOptions<BoxFolderData, Error, CreateBoxFolderData> = {}): Pick<
  CreateBoxFolderMutationResult,
  'isLoading'
> & {
  create: CreateBoxFolderMutationResult['mutate'];
  createAsync: CreateBoxFolderMutationResult['mutateAsync'];
} => {
  const mutation = useMutation<BoxFolderData, Error, CreateBoxFolderData>(
    createBoxFolder,
    {
      onSuccess,
      onError,
    }
  );

  return {
    create: mutation.mutate,
    createAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};

type BulkCreateBoxFolderAudienceMappingData = {
  programId: number;
  folderId: string;
  audienceIds: string[];
};

type BulkCreateBoxFolderAudienceMappingMutationResult = UseMutationResult<
  void[],
  Error,
  BulkCreateBoxFolderAudienceMappingData
>;

export const useBulkCreateBoxFolderAudienceMapping = ({
  onSuccess,
  onError,
}: MutationOptions<
  void[],
  Error,
  BulkCreateBoxFolderAudienceMappingData
> = {}): Pick<BulkCreateBoxFolderAudienceMappingMutationResult, 'isLoading'> & {
  bulkCreate: BulkCreateBoxFolderAudienceMappingMutationResult['mutate'];
  bulkCreateAsync: BulkCreateBoxFolderAudienceMappingMutationResult['mutateAsync'];
} => {
  const mutation = useMutation<
    void[],
    Error,
    BulkCreateBoxFolderAudienceMappingData
  >(
    ({ programId, folderId, audienceIds }) => {
      return Promise.all(
        audienceIds.map((audienceId) =>
          createBoxFolderAudienceMapping(programId, folderId, audienceId)
        )
      );
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    bulkCreate: mutation.mutate,
    bulkCreateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};

// export const useBoxDeleteFolder = ({
//   onSuccess,
//   onError,
// }: MutationOptions<BoxFolderData, Error> = {}): {
//   deleteFolder: (folderId: number) => void;
// } => {
//   const { id: programId } = useProgram();
//   const { id: userId } = useUser();
//   const queryClient = useQueryClient();
//   const deleteFolder = (folderId: number) => {
//     deleteBoxMangementFolder(programId, userId, folderId)
//       .then((folder) => {
//         queryClient.invalidateQueries(['box-management-infinite']);
//         if (onSuccess) onSuccess(folder);
//       })
//       .catch((err) => {
//         if (onError) onError(err.message);
//       });
//   };

//   return { deleteFolder };
// };
