import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useUser } from 'contexts/user';
import { Author } from 'models/author';
import { ProgramAuthor } from 'models/programAuthor';
import { fetchAuthorPage } from 'services/api-authors';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useChannelContributorsQuery } from './channel-contributors';
import { useInfiniteApiQuery } from './common';
import { useFeatureFlags } from './useFeatureFlags';

// TODO: Most of this needs to be moved to backend.
// TODO: Consolidate with AuthorSelect which is a copy of this.

export type ProgramOrAuthor = ProgramAuthor | Author;

export const useAuthorsList = (
  searchText: string,
  excludeAudiences: boolean
): {
  data: ProgramOrAuthor[];
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
} => {
  const { id: programId, programAuthor } = useProgram();
  const user = useUser();
  const { data: channelContributorsData = [] } = useChannelContributorsQuery();
  const { role, contributorChannelIds } = usePermissions();

  const { channelContributorSelectAuthorsEnabled } = useFeatureFlags();

  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;

  const contributorIds = channelContributorsData
    .filter((d) => contributorChannelIds?.includes(d.channelId))
    .map((c) => c.contributorId);

  const filterByChannelContributor =
    role === 'channel_contributor' && !channelContributorSelectAuthorsEnabled;

  const {
    isLoading,
    data = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteApiQuery('authors', fetchAuthorPage, {
    programId,
    useAuthorAliases: !!useAuthorAliases,
    query: searchText,
    exclude_audiences: excludeAudiences,
    statuses: useAuthorAliases ? ['active'] : [],
    pageSize: 10,
  });

  // TODO: UserId should be added to elasticSearch query on backend and should be subject to same
  //  querying from searchText as other authors.
  const userAndProgram = [
    {
      userId: user.id,
      displayName: user.displayName ?? `${user.firstName} ${user.lastName}`,
      defaultDisplayName: user.displayName ?? '',
      avatarUrl: user.avatarUrl,
      email: user.email,
      role: user.role,
    },
    {
      userId: -1, // will later replace this by null
      displayName: programAuthor?.displayName ?? '',
      defaultDisplayName: programAuthor?.displayName ?? '',
      avatarUrl: programAuthor?.avatarUrl,
    },
  ].filter((a) =>
    searchText
      ? a.displayName.toLowerCase().includes(searchText.toLowerCase())
      : true
  );

  const cleanedData = useAuthorAliases
    ? data
    : data
        .filter((a) => a.userId !== user.id)
        .concat(userAndProgram)
        .filter(
          (a) =>
            // TODO: This should be filtered on backend
            // Only check contributorIds if filterByChannelContributor is enabled or there are no contributorIds
            !filterByChannelContributor ||
            contributorIds.length < 1 ||
            (filterByChannelContributor && contributorIds.includes(a.userId))
        )
        .sort((a, b) => {
          const nameA =
            a.displayName?.toLowerCase() || a.defaultDisplayName?.toLowerCase();
          const nameB =
            b.displayName?.toLowerCase() || b.defaultDisplayName?.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

  // We need the program author to have id = null, but the logic above assumes the authors have ids,
  // and the local filtering groups them first, so let's convert the program author to a ProgramAuthor type
  // and setting its id as null before returning the list.
  const finalData: ProgramOrAuthor[] = cleanedData.map((item) =>
    item.userId === -1
      ? (({ ...item, userId: null } as unknown) as ProgramAuthor)
      : item
  );

  return {
    data: finalData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
};
