import React from 'react';
import { Box } from 'DesignSystem/Components';
import { FieldLabel } from 'DesignSystem/Typography';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import { EmailSenderAlias } from 'models/publisher/settings';
import {
  CommunicationStep,
  DeliveryChannel,
  DeliveryChannels,
} from 'models/journeys/journey';
import { isCommunicationStepError } from 'models/journeys/journey-errors';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import { EmailConfig } from './channel-configs';
import { useJourneyValidationErrors } from '../../JourneyErrors/useJourneyValidationErrors';
import { NotificationCard } from '../../../../Editors/Publisher/Deliver/Notifications/NotificationCard';

export type DeliveryChannelOption = {
  id?: string;
  name: keyof DeliveryChannels;
  label: string;
};

const CHANNEL_OPTIONS: DeliveryChannelOption[] = [
  {
    name: 'email',
    label: 'Email',
  },
];

// TODO: Implement when we have more info
const useChannelPermissions = (): (keyof DeliveryChannels)[] => {
  return ['email', 'push', 'assistant'];
};
const DEFAULT_CHANNEL_DATA: Record<keyof DeliveryChannels, DeliveryChannel> = {
  email: {
    name: 'email',
    previewText: '',
    subject: '',
  },
  push: {
    name: 'push',
    text: '',
  },
  assistant: {
    name: 'assistant',
  },
};

const useDeliveryChannels = (step: CommunicationStep) => {
  const permittedChannels = useChannelPermissions();
  const deliveryChannels: (DeliveryChannel & {
    label: string;
    selected: boolean;
    disabled: boolean;
  })[] = CHANNEL_OPTIONS.map(({ name, label }) => {
    const existingChannel: DeliveryChannel | undefined = step.channels.find(
      (c) => c.name === name
    );

    return {
      ...(existingChannel ?? DEFAULT_CHANNEL_DATA[name]),
      label,
      selected: !!existingChannel,
      disabled: !permittedChannels.includes(name),
    };
  });

  return deliveryChannels;
};

export const ReadOnlyCommunicationConfig: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const deliveryChannels = useDeliveryChannels(step);

  return (
    <FlexLayout.Flex spread>
      <Box width="50%">
        {deliveryChannels.map((args) => {
          const { name } = args;

          return (
            <>
              {name === 'email' && (
                <Box>
                  <Box>
                    <FieldLabel>Email Alias</FieldLabel>
                    <Box margin={[8, 0, 0, 0]}>
                      {args.emailSenderAlias?.senderEmail}
                    </Box>
                  </Box>
                  <Box margin={[24, 0, 0, 0]}>
                    <FieldLabel>Subject and Preview Text</FieldLabel>
                    <NotificationCard
                      notification={{
                        text: args.subject,
                        previewText: args.previewText,
                        order: 0,
                      }}
                      disablePush
                    />
                  </Box>
                </Box>
              )}
            </>
          );
        })}
      </Box>
    </FlexLayout.Flex>
  );
};

export const CommunicationConfig: React.FC<{
  step: CommunicationStep;
  onUpdateStep: (step: CommunicationStep) => void;
}> = ({ step, onUpdateStep }) => {
  const { errorsForStep, resolveErrors } = useJourneyValidationErrors();
  const stepErrors = errorsForStep(step.id);
  const errors = isCommunicationStepError(stepErrors) ? stepErrors : undefined;
  const deliveryChannels = useDeliveryChannels(step);

  const handleEmailAliasChange = (
    channel: keyof DeliveryChannels,
    emailSenderAlias: EmailSenderAlias
  ) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((c) =>
      c.name === channel
        ? {
            ...c,
            emailSenderAlias,
            programContactAddressId: parseInt(emailSenderAlias.id, 10),
          }
        : c
    );

    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelAddress']);
  };

  const handleSubjectChange = (
    channel: keyof DeliveryChannels,
    subject: string
  ) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((c) =>
      c.name === channel ? { ...c, subject } : c
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelSubject']);
  };

  const handlePreviewTextChange = (
    channel: keyof DeliveryChannels,
    previewText: string
  ) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((c) =>
      c.name === channel ? { ...c, previewText } : c
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelPreview']);
  };

  const { data: defaultAddress } = useDefaultEmailAddress();

  return (
    <FlexLayout.Flex spread>
      <Box margin={[16, 0, 0, 0]} width="720px">
        {deliveryChannels.map((args) => {
          const { name, disabled, label } = args;
          return (
            <>
              {name === 'email' && (
                <EmailConfig
                  key={`email-config-${name}-${label}`}
                  errors={errors}
                  disabled={disabled}
                  onEmailAliasChange={(alias: EmailSenderAlias) => {
                    handleEmailAliasChange(name, alias);
                  }}
                  emailSenderAlias={args.emailSenderAlias || defaultAddress}
                  subject={
                    args.subject === 'Default' || !args.subject
                      ? ''
                      : args.subject
                  }
                  previewText={
                    args.previewText === 'Default' || !args.previewText
                      ? ''
                      : args.previewText
                  }
                  onSubjectChange={(subject) =>
                    handleSubjectChange(name, subject)
                  }
                  onPreviewTextChange={(previewText) =>
                    handlePreviewTextChange(name, previewText)
                  }
                />
              )}
            </>
          );
        })}
      </Box>
    </FlexLayout.Flex>
  );
};
