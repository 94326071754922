import React from 'react';
import { Placeholder } from 'DesignSystem/Components/Placeholder';
import { Plus } from 'shared/icons';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import styles from '../video.module.css';

type CaptionFormProps = {
  onUploadClick: () => void;
};

export const CaptionsDropzone: React.FC<CaptionFormProps> = ({
  onUploadClick,
}) => {
  return (
    <div className={styles.clickable}>
      <Placeholder onClick={onUploadClick}>
        <Text.Body block semibold>
          Add Captions File
        </Text.Body>
        <Button circle compact icon={<Plus />} />
        <Text.Body block color={Text.color.gray50}>
          Use an existing subtitles file (eg. SRT, VTT).
        </Text.Body>
      </Placeholder>
    </div>
  );
};
