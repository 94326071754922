import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { ConfirmDeleteV2 as ConfirmDelete } from 'shared/ConfirmDelete';
import { Download, SettingsCogGear, Trash } from 'shared/icons';
import { FetchedCaption } from 'services/api-captions';
import { useCaptionPreview } from '../../hooks/useCaptionPreview';
import styles from './styles.module.css';

export const CaptionPreview: React.FC<{
  isDragging?: boolean;
  onEdit: () => void;
  onRemove: () => void;
  caption: FetchedCaption;
  filename: string;
  canDownload?: boolean;
}> = ({ caption, onEdit, onRemove, filename, canDownload }) => {
  const { isDeleting, setDeleting, language, showDialog } = useCaptionPreview(
    caption
  );

  const url = new URL(caption.url);
  const { pathname } = url;
  const extension = pathname.split('.').pop();

  return (
    <Box
      radius={4}
      relative
      data-test="block-caption-editor-preview"
      color={Text.background.gray05}
    >
      {isDeleting && (
        <ConfirmDelete
          entity="Caption"
          onRemove={(event?: React.MouseEvent) => {
            if (event) event.stopPropagation();
            onRemove();
            setDeleting(false);
          }}
          cancelRemove={() => setDeleting(false)}
        />
      )}
      <Flex center style={{ padding: '8px 16px' }}>
        <FlexItem widen start>
          <Text.Body bold>{language}</Text.Body>
        </FlexItem>
        <Button
          title="Edit Caption"
          minimal
          clearText
          icon={<SettingsCogGear className={styles.settingsButton} />}
          onClick={onEdit}
        />
        <Button
          title="Remove Caption"
          minimal
          clearText
          icon={<Trash className={styles.removeCaptionButton} />}
          onClick={showDialog}
        />
        {canDownload && (
          <a
            target="_blank"
            rel="noreferrer"
            className={styles.downloadLink}
            href={caption.url}
            download={`${filename}-caption-${caption.language}.${extension}`}
          >
            <Download style={{ width: '18px' }} />
          </a>
        )}
      </Flex>
    </Box>
  );
};
