import { useProgram } from 'contexts/program';
import { useEffect, useState } from 'react';
import type { Content } from '../models/content';
import {
  CampaignResultsData,
  fetchCampaignResultsData,
} from '../services/api-insights';

interface IUseCampaignResults {
  results: null | CampaignResultsData;
  isLoading: boolean;
}

export const useCampaignResults = (content: Content): IUseCampaignResults => {
  const { id: programId } = useProgram();
  const [results, setResults] = useState<CampaignResultsData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded || isLoading) {
      return;
    }

    setIsLoading(true);
    fetchCampaignResultsData({
      programId,
      contentId: content.id,
    })
      .then((d) => {
        setResults(d.data);
        setIsLoading(false);
        setLoaded(true);
      })
      .catch(() => {
        setIsLoading(false);
        setLoaded(true);
      });
  }, [content, isLoading, loaded, programId, setIsLoading]);

  return {
    results,
    isLoading,
  };
};
