import { deepMerge } from 'utility/deep-merge';
import { StyleOptions, Styling, StyleData } from 'models/donkey';

export type ColorField = Exclude<StyleOptions['colors'], undefined>[number];
export type FontField = Exclude<StyleOptions['fonts'], undefined>[number];
export type SizeField = Exclude<StyleOptions['sizes'], undefined>[number];

export const BASE_STYLING: Styling = {
  colors: {
    page: '#ffffff',
    canvas: '#fbfbfd',
    highlights: '#EEEEEE',
    borders: '#EEEEEE',
    headlines: '#041918',
    subhead: '#ACACAC',
    title: '#000000',
    subtitle: '#ACACAC',
    body: '#000000',
    caption: '#747474',
    buttonFg: '#03A4FF',
    buttonBg: '#e3e7eF',
    button: '#03A4FF',
    link: '#03A4FF',
    divider: '#000000',
  },
  sizes: {
    headlines: 24,
    subhead: 14,
    title: 18,
    subtitle: 14,
    body: 16,
    caption: 12,
    button: 14,
    link: 16,
    buttonRadiuses: 0,
    radiuses: 0,
    margins: 32,
    topSpacing: 0,
    bottomSpacing: 0,
    imageWidth: 100,
    height: 10,
    dividerHeight: 10,
    dividerVerticalSpacing: 5,
    emailTopPadding: 64,
    emailBottomPadding: 64,
  },
  fonts: {
    headlines: 'Arial, Helvetica, sans-serif',
    subhead: 'Arial, Helvetica, sans-serif',
    title: 'Arial, Helvetica, sans-serif',
    subtitle: 'Arial, Helvetica, sans-serif',
    body: 'Arial, Helvetica, sans-serif',
    caption: 'Arial, Helvetica, sans-serif',
    button: 'Arial, Helvetica, sans-serif',
    link: 'Arial, Helvetica, sans-serif',
  },
};

export type FontOption = {
  value: string;
  label: string;
  generic?: 'serif' | 'sans-serif';
  id?: string;
  url?: string;
};

// generics are not supported in web fonts and "value" is used to create fallbacks
// in useFontEditor. We could either sanitize the value to remove serif/sans-serif
// or keep it as a separate property.
type WebSafeFontOption = FontOption & { generic: 'serif' | 'sans-serif' };
export const WEB_SAFE_FONTS: WebSafeFontOption[] = [
  { value: 'Arial, Helvetica', label: 'Arial', generic: 'sans-serif' },
  { value: 'Georgia', label: 'Georgia', generic: 'serif' },
  { value: 'Tahoma, Geneva', label: 'Tahoma', generic: 'sans-serif' },
  {
    value: 'Times New Roman, Times',
    label: 'Times New Roman',
    generic: 'serif',
  },
  {
    value: 'Trebuchet MS, Helvetica',
    label: 'Trebuchet MS',
    generic: 'sans-serif',
  },
  { value: 'Verdana, Geneva', label: 'Verdana', generic: 'sans-serif' },
];

export function findWebSafe(
  labelOrValue?: string
): WebSafeFontOption | undefined {
  if (!labelOrValue) return undefined;
  return WEB_SAFE_FONTS.find(({ label, value }) =>
    [label, value].includes(labelOrValue)
  );
}

export function isWebSafe(labelOrValue: string): boolean {
  return !!findWebSafe(labelOrValue);
}

export function hasUnsafeWebFonts(styles: Record<string, string>): boolean {
  let result = false;
  Object.entries(styles).forEach(([, fonts]) => {
    const [font] = fonts.split(', ');
    if (!isWebSafe(font)) {
      result = true;
    }
  });
  return result;
}

export function isFontOption(
  options: Array<FontOption>,
  data?: FontOption | unknown
): data is FontOption {
  if (typeof data === 'object' && data !== null) {
    const { value } = data as { [key: string]: unknown };
    if (options.some((option) => option.value === value)) {
      return true;
    }
  }
  return false;
}

export function sanitizeFontOption(
  options: Array<FontOption>,
  option?: FontOption | { value?: string },
  field: FontField = 'body'
): FontOption {
  if (isFontOption(options, option)) {
    const withLabel = options.find((opt) => option.value === opt.value);
    if (withLabel) {
      return { value: option.value, label: withLabel.label };
    }
  }
  const defaultValue = BASE_STYLING.fonts[field];
  const defaultOption = options.find((opt) => opt.value === defaultValue);
  if (defaultOption) {
    return defaultOption;
  }
  throw new Error('no default font configured');
}

export function mergeStyles<S = Styling>(style: S, ...styles: StyleData[]): S {
  let merged = deepMerge(style, {});
  const q = [...styles];
  while (q.length) {
    merged = deepMerge(merged, q.shift() as Partial<S>);
  }
  return merged;
}
