import * as React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { NumericInput } from 'shared/NumericInput';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Section } from 'DesignSystem/Form';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { FlipSwitch } from 'shared/FlipSwitch';
import {
  useUpdateUserGeneratedContentTopicLimit,
  useUserGeneratedContentTopicLimitQuery,
} from 'hooks/feature-flags';
import { HeaderActions } from '../Shortcuts/HeaderActions';
import { Spinner } from '../Shared/Spinner';
import styles from './topics.module.css';

const DEFAULT_LIMIT = 2;

export const TopicsSettingsEditPage: React.FC<RouteComponentProps> = () => {
  const [limitEnabled, setLimitEnabled] = React.useState(false);
  const [limitCount, setLimitCount] = React.useState(DEFAULT_LIMIT);

  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();

  const { id: programId } = useProgram();

  const {
    isLoading,
    errorMessage,
    data,
  } = useUserGeneratedContentTopicLimitQuery(programId);

  const { update } = useUpdateUserGeneratedContentTopicLimit(programId, {
    onError: () => {
      setFlashMessage({
        message: 'Could not update the global topic settings',
        severity: 'error',
      });
    },
    onSuccess: () => {
      setFlashMessage({
        message: 'Successfully updated the global topic settings',
        severity: 'info',
      });
      navigate(`/${programId}/configure/topics/settings`);
    },
  });

  const topicLimitCount = data?.userGeneratedContentTopicLimit?.value;
  const topicLimitEnabled = !!topicLimitCount && +topicLimitCount > 0;

  React.useEffect(() => {
    if (topicLimitCount !== undefined) {
      setLimitEnabled(topicLimitEnabled);

      const numberLimit = +topicLimitCount;
      if (numberLimit > 0) {
        setLimitCount(numberLimit);
      } else if (topicLimitEnabled) {
        setLimitCount(DEFAULT_LIMIT);
      }
    }
  }, [topicLimitCount, topicLimitEnabled]);

  const onSave = () => {
    update({
      programId,
      type: 'user_generated_content_topic_limit',
      label: 'user_generated_content_topic_limit',
      value: `${limitEnabled ? limitCount : 0}`,
    });
  };
  return (
    <WithPermission permissions={['configureTopicLimit']}>
      <FormPage
        title="Global Topic Settings"
        breadcrumbs={[
          { to: '../../..', label: 'Configure' },
          { to: '../..', label: 'Topics' },
          { to: '..', label: 'Global Settings' },
        ]}
        actionsOverride={<HeaderActions handleSave={onSave} />}
      >
        {isLoading && <Spinner />}
        {errorMessage && <>{errorMessage}</>}
        {!isLoading && !errorMessage && (
          <Section
            title="Topic Posting Limit"
            description="Restrict the number of topics a member can post in to prevent spam and maintain content relevance."
          >
            <div className={styles.formToggleRow}>
              <div className="text-body-2">
                Restrict Number of Topics Users Can Post To:&nbsp;
                <b>{limitEnabled ? 'ON' : 'OFF'}</b>
              </div>
              <FlipSwitch
                on={limitEnabled}
                onChange={(value) => setLimitEnabled(value || false)}
                shape="rounded"
              />
            </div>
            {limitEnabled ? (
              <div className={styles.formLimitValueRow}>
                <div className="text-body-2">
                  <b>Select Max Number of Topics</b>
                  <div className={styles.formLimitValueInputContainer}>
                    <NumericInput
                      value={limitCount}
                      onChange={(value) => setLimitCount(value)}
                      minValue={1}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </Section>
        )}
      </FormPage>
    </WithPermission>
  );
};
