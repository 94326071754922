import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import Document from '@tiptap/extension-document';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import * as TiptacText from '@tiptap/extension-text';
import { lowlight } from 'lowlight/lib/core';
import ParseError from 'srt-validator/dist/utils/parse-error';
import { Box } from 'DesignSystem/Components';
import cx from 'classnames';
import {
  EditorContent,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  useEditor,
} from '@tiptap/react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { ChevronDownAlt, ChevronUpAlt, Trash } from 'shared/icons';
import styles from '../video.module.css';

type CaptionEditorProps = {
  captionText?: string;
  validationErrors: ParseError[] | undefined;
  onReplaceClick: () => void;
  onRemoveClick: () => void;
};

export type CodeBlockProps = {
  extension: {
    storage: {
      lines: number;
      errors: ParseError[];
    };
  };
};

export const CaptionsEditor: React.FC<CaptionEditorProps> = ({
  captionText,
  validationErrors = [],
  onReplaceClick,
  onRemoveClick,
}) => {
  const length = captionText?.split('\n').length || 0;

  const editor = useEditor(
    {
      editable: false,
      editorProps: {
        attributes: {
          class: styles.captionsEditor,
        },
      },
      extensions: [
        Document.extend({
          content: 'codeBlock+',
        }),
        TiptacText.Text,
        CodeBlockLowlight.extend({
          name: 'codeBlock',
          group: 'codeBlock',
          addStorage() {
            return {
              lines: length,
              errors: validationErrors,
            };
          },
          addNodeView() {
            return ReactNodeViewRenderer(CodeBlockComponent);
          },
        }).configure({
          defaultLanguage: 'plaintext',
          exitOnArrowDown: false,
          exitOnTripleEnter: false,
          lowlight,
        }),
      ],
      content: {
        type: 'doc',
        content: [
          {
            type: 'codeBlock',
            content: [
              {
                type: 'text',
                text: captionText,
              },
            ],
          },
        ],
      },
    },
    [captionText, length]
  );
  if (!captionText) return null;

  return (
    <Box color={Text.color.gray90} background={Text.color.gray00}>
      <EditorContent editor={editor} />
      <Flex spread color={Text.background.gray05} className={styles.replace}>
        <Button onClick={onReplaceClick} label="Replace" layoutOnly />
        <Button onClick={onRemoveClick} icon={<Trash />} layoutOnly />
      </Flex>
    </Box>
  );
};

const CodeBlockComponent = (props: CodeBlockProps) => {
  const { extension } = props;
  const { storage } = extension;
  const lines = storage?.lines === undefined ? 1 : storage.lines;
  const { errors } = storage;
  const lineNumbers = CodeBlockLineNumbers(lines < 6 ? 6 : lines, errors);
  return (
    <NodeViewWrapper className="code-block">
      <Flex start alignStart className={styles.subtitlesEditorWrapper}>
        <pre id="code-block-line-numbers" className={styles.lineNumbers}>
          {lineNumbers}
        </pre>
        <pre id="code-block-content" className={styles.subtitlesWrapper}>
          <NodeViewContent as="code" />
        </pre>
      </Flex>
    </NodeViewWrapper>
  );
};

const CodeBlockLineNumbers = (
  lines: number,
  errors: ParseError[]
): JSX.Element[] => {
  const newLines: JSX.Element[] = [];
  const lineNumbers = errors.map((e) => e.lineNumber as number);

  const lineHasError = (ln: number) => {
    return lineNumbers.includes(ln);
  };

  const hasErrorsBefore = (ln: number) => {
    return lineNumbers.indexOf(ln) > 0;
  };

  const hasErrorsAfter = React.useCallback(
    (ln: number) => {
      return lineNumbers.indexOf(ln) < lineNumbers.length - 1;
    },
    [lineNumbers]
  );

  const scrollToPreviousError = (i: number) => {
    if (hasErrorsBefore(i)) {
      const prevError = errors
        .slice()
        .reverse()
        .find((e) => e.lineNumber < i);
      if (prevError) {
        const line = document.getElementById(
          `codeline-id-${prevError.lineNumber}`
        );
        if (line) {
          line.scrollIntoView();
        }
      }
    }
  };

  const scrollToNextError = (i: number) => {
    if (hasErrorsAfter(i)) {
      const nextError = errors.slice().find((e) => e.lineNumber > i);
      if (nextError) {
        const line = document.getElementById(
          `codeline-id-${nextError.lineNumber}`
        );
        if (line) {
          line.scrollIntoView();
        }
      }
    }
  };

  for (let i = 1; i <= lines; i += 1) {
    const codeNode: JSX.Element = (
      <code
        className={cx(styles.lineNumberItem, {
          [styles.subtitleError]: lineHasError(i),
        })}
        id={`codeline-id-${i}`}
        key={`codeline-key-${i}`}
      >
        {lineHasError(i) && (
          <Flex spread className={styles.subtitlesLineError}>
            <span>{errors.find((e) => e.lineNumber === i)?.message}</span>
            <Flex spread className={styles.buttonsWrapper}>
              <Button
                minimal
                layoutOnly
                disabled={!hasErrorsBefore(i)}
                className={cx(
                  {
                    [styles.navigationButtonDisabled]: !hasErrorsBefore(i),
                  },
                  styles.subtitleErrorsNavigationButton
                )}
                onClick={() => scrollToPreviousError(i)}
                icon={<ChevronUpAlt />}
              />
              <Button
                className={cx(
                  {
                    [styles.navigationButtonDisabled]: !hasErrorsAfter(i),
                  },
                  styles.subtitleErrorsNavigationButton
                )}
                minimal
                disabled={!hasErrorsAfter(i)}
                layoutOnly
                onClick={() => scrollToNextError(i)}
                icon={<ChevronDownAlt />}
              />
            </Flex>
          </Flex>
        )}
        <>{i}</>
      </code>
    );
    newLines.push(codeNode);
  }

  return newLines;
};
