import React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { UpperBar } from 'shared/NavigationBars/FixedBars';
import { navigate } from '@reach/router';
import { JourneyMode } from 'models/journeys/journey';
import { ConfirmModal } from 'DesignSystem/Components';
import { useProgram } from 'contexts/program';
import { useJourneyTabState } from 'contexts/journey-tab';
import { useJourneyState } from 'contexts/journey';
import { useBeforeNavigate, useBeforeUnload } from 'hooks/useBeforeUnload';
import styles from './styles.module.css';
import { JourneyErrors } from '../JourneyErrors';
import { CloseButton } from './Buttons';
import CallToActionButton from './CallToActionButton';
import { TitleBar } from './TitleBar';
import {
  CloudStatus,
  useJourneyPersistenceStatus,
} from './useJourneyPersistenceStatus';

const UNSAVED_CHANGES_MESSAGE =
  'This journey has unsaved changes. Are you sure that you want to exit?';

export const JourneyCanvasHeader: React.FC = () => {
  const { id: programId } = useProgram();
  const { journeyMode } = useJourneyState();
  const persistenceStatus = useJourneyPersistenceStatus();
  const hasUnsavedChanges =
    persistenceStatus.cloudStatus !== CloudStatus.UpToDate;
  useBeforeUnload(hasUnsavedChanges, UNSAVED_CHANGES_MESSAGE);
  useBeforeNavigate(hasUnsavedChanges, UNSAVED_CHANGES_MESSAGE);

  const [showErrorsModal, setShowErrorsModal] = React.useState(false);
  const [showCloseModal, setShowCloseModal] = React.useState(false);

  const { journeyTabState } = useJourneyTabState();
  const handleClose = () => {
    if (hasUnsavedChanges) {
      setShowCloseModal(true);
    } else {
      closeJourney();
    }
  };

  const dismissCloseModal = () => {
    setShowCloseModal(false);
  };

  const closeJourney = React.useCallback(() => {
    if (journeyTabState === 'archive') {
      navigate(`/${programId}/app/journeys/archive`);
    } else {
      navigate(`/${programId}/app/journeys`);
    }
  }, [programId, journeyTabState]);

  const openErrorsModal = React.useCallback(() => {
    setShowErrorsModal(true);
  }, []);

  const closeErrorsModal = React.useCallback(() => {
    setShowErrorsModal(false);
  }, []);

  return (
    <UpperBar className={styles.journeyCanvasHeader}>
      {showCloseModal && (
        <ConfirmModal
          title="Unsaved changes"
          confirmLabel="Proceed"
          onConfirm={closeJourney}
          onCancel={dismissCloseModal}
        >
          {UNSAVED_CHANGES_MESSAGE}
        </ConfirmModal>
      )}
      <TitleBar canEditTitle={journeyMode === JourneyMode.Edit} />
      <Flex end>
        {journeyMode === JourneyMode.Edit ? (
          <JourneyErrors
            showModal={showErrorsModal}
            closeModal={closeErrorsModal}
          />
        ) : null}
        <CallToActionButton openPublishErrorsModal={openErrorsModal} />
        <CloseButton handleClose={handleClose} />
      </Flex>
    </UpperBar>
  );
};
