import React from 'react';
import { IColumn, UserActivityStreamCard } from '../UserActivityStreamCard';

export const OmniUserActivityCard: React.FC<{
  campaignId: number;
}> = ({ campaignId }) => {
  const formatDate = (date?: string) => {
    return date && date !== null
      ? new Date(date).toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })
      : '';
  };

  const columns: IColumn[] = [
    { key: 'user_name', header: 'Name', width: '15%' },
    { key: 'email', header: 'Email', width: '15%' },
    {
      key: 'last_previewed',
      header: 'Last Previewed',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_opened',
      header: 'Last Opened',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_engaged',
      header: 'Last Engaged',
      width: '15%',
      formatCell: formatDate,
    },
    {
      key: 'last_error',
      header: 'Last Error',
      width: '15%',
      formatCell: formatDate,
    },
  ];

  return (
    <UserActivityStreamCard
      campaignId={campaignId}
      columns={columns}
      hasSearch
    />
  );
};
