import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  axisBottom,
  axisLeft,
  AxisScale,
  format,
  interpolateSpectral,
  line,
  quantize,
  scaleLinear,
  ScaleOrdinal,
  scaleOrdinal,
  select,
} from 'd3';
import cx from 'classnames';
import { ChartDimensions } from './Util';
import { NoDataChart } from './NoDataChart';

import styles from './data-table.module.css';

export type LineChartDimensions = ChartDimensions;

export type LineData = {
  label: string;
  data: Array<PointData>;
};

export type PointData = {
  label?: string;
  value: number;
};

export type CustomDrawingFunction = (opts: {
  lines?: Array<LineData>;
  chartDimensions: LineChartDimensions;
  scaleX?: AxisScale<string> | AxisScale<number>;
  scaleY?: AxisScale<string> | AxisScale<number>;
  colorScale?: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>;
}) => React.ReactFragment;

interface ILineChart {
  lines: Array<LineData>;
  domain?: [number, number];
  chartDimensions?: LineChartDimensions;
  customDrawing?: CustomDrawingFunction;
  customColors?: ScaleOrdinal<string, unknown>;
  hideXAxis?: boolean;
  hideYAxis?: boolean;
}

export const LineChart: React.FC<ILineChart> = ({
  lines,
  domain = [0, 1],
  chartDimensions = {
    height: 240,
    width: 960,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  customDrawing,
  customColors,
  hideXAxis = false,
  hideYAxis = false,
}: ILineChart) => {
  const { height, width, padding } = chartDimensions;
  const firstLine = lines?.[0];
  const firstLineLength = firstLine?.data.length - 1 || 1;

  const [hoverTarget, setHoverTarget] = useState<number | null>(null);

  const yAxis = useRef<SVGGElement>(null);
  const xAxis = useRef<SVGGElement>(null);
  const axisMargin = {
    top: 14,
    bottom: 24,
    left: 24,
    right: 14,
  };

  const xStart = padding.left + axisMargin.left;
  const xEnd = width - padding.left - padding.right;

  const x = scaleLinear([0, firstLineLength], [xStart, xEnd]);
  const y = scaleLinear(domain, [
    height - padding.bottom - axisMargin.bottom,
    padding.top - axisMargin.top,
  ]);

  const drawLine = line((d, i) => x(i), y); // .curve(curveCardinal);

  const colors =
    customColors ??
    scaleOrdinal()
      .domain(lines?.map((d) => d.label) ?? [])
      .range(
        quantize(
          (t) => interpolateSpectral(t * 0.8 + 0.1),
          lines?.length || 0
        ).reverse()
      );

  const drawLines = (data: Array<LineData>) => {
    return data.map((lineData) => {
      return (
        <path
          key={`line-${lineData.label}-${lineData.data}`}
          fill="none"
          stroke={colors(lineData.label) as string}
          strokeWidth={3}
          d={drawLine(lineData.data.map(({ value }) => value))?.toString()}
        />
      );
    });
  };

  const drawDetailBox = (data: Array<LineData>) => {
    const boxes = [];
    const rectWidth = 64;
    const rectHeight = 24 + data.length * 24;
    const rectPadding = 8;

    for (let i = 0; i <= firstLineLength; i += 1) {
      const xValue = x(i);
      const yValue = Math.floor(
        (height -
          padding.top -
          axisMargin.top -
          padding.bottom -
          axisMargin.bottom) /
          2
      );

      const xPos =
        xValue + padding.left + padding.right >= width * 0.95
          ? xValue - 74 // Max length of label
          : xValue + 8; // Padding

      let detailLine = null;

      const e = data.map((lineData, j) => {
        const yPos = yValue + 20 * j;
        const pointData = lineData.data[0];
        const radius = 4;

        const boundingBoxWidth = 12;

        detailLine = (
          <React.Fragment key={`lineDetails-${lineData.label}`}>
            <rect
              x={xValue - boundingBoxWidth / 2}
              width={boundingBoxWidth}
              y={0}
              height={height - padding.bottom - padding.top}
              pointerEvents="bounding-box"
              onMouseOver={() => setHoverTarget(i)}
              onMouseLeave={() => setHoverTarget(null)}
              fill="transparent"
              stroke="transparent"
            />
            <line
              x1={xValue}
              x2={xValue}
              y1={0}
              y2={height - padding.bottom - padding.top}
              pointerEvents="bounding-box"
              stroke="rgba(3, 2, 41, 0.7)"
              fill="rgba(3, 2, 41, 0.7)"
            />
          </React.Fragment>
        );

        return (
          <g key={`point-detail-${pointData.label}-${pointData.value}`}>
            <circle
              cx={xPos + radius + rectPadding}
              cy={yPos - 1}
              r={radius}
              stroke={colors(lineData.label) as string}
              fill="white"
              strokeWidth="3"
            />
            <text
              x={xPos + radius * 2 + rectPadding + 4}
              y={yPos}
              dominantBaseline="middle"
            >
              {Number(lineData.data[i].value / domain[1]).toLocaleString('en', {
                style: 'percent',
                minimumFractionDigits: 0,
              })}
            </text>
          </g>
        );
      });

      boxes.push(
        <g
          key={`box-${data[i]?.label}`}
          className={cx({ [styles.hidden]: hoverTarget !== i })}
        >
          {detailLine}
          <rect
            x={xPos}
            y={yValue - 40}
            width={rectWidth}
            height={rectHeight}
            stroke="grey"
            fill="white"
          />
          <text x={xPos + rectPadding} y={yValue - 20} stroke="black">
            Day {i}
          </text>
          {e}
        </g>
      );
    }

    return <g>{boxes}</g>;
  };

  const drawMarkers = (data: Array<LineData>) => {
    return data.map((lineData) => {
      return lineData.data.map((pointData, i) => {
        const xValue = x(i);
        const yValue = y(pointData.value);
        const radius = 4;
        return (
          <g
            onMouseEnter={() => setHoverTarget(i)}
            onMouseLeave={() => setHoverTarget(null)}
            key={`${pointData.value}-${pointData.label}`}
          >
            <circle
              cx={xValue}
              cy={yValue}
              r={radius}
              stroke={colors(lineData.label) as string}
              strokeWidth="3"
            />
          </g>
        );
      });
    });
  };

  useEffect(() => {
    if (yAxis.current === null) {
      return;
    }

    select(yAxis.current).call(axisLeft(y).ticks(5).tickFormat(format('.0%')));
  }, [yAxis, y]);

  useEffect(() => {
    if (xAxis.current === null) {
      return;
    }

    select(xAxis.current).call(axisBottom(x).ticks(0));
  }, [xAxis, x]);

  const drawCustom = useMemo(() => {
    return customDrawing
      ? customDrawing({
          lines,
          chartDimensions,
          scaleX: x,
          scaleY: y,
          colorScale: colors,
        })
      : undefined;
  }, [chartDimensions, colors, customDrawing, lines, x, y]);

  return lines === undefined || lines.length === 0 ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg viewBox={`0 0 ${width} ${height}`}>
      {drawCustom}
      {drawLines(lines)}
      {!hideXAxis && (
        <g
          ref={xAxis}
          transform={`translate(0,${height - axisMargin.bottom})`}
        />
      )}
      {!hideYAxis && (
        <g ref={yAxis} transform={`translate(${axisMargin.left},0)`} />
      )}
      <g>{drawDetailBox(lines)}</g>
      <g fill="white" stroke="currentColor" strokeWidth="1.5">
        {drawMarkers(lines)}
      </g>
    </svg>
  );
};
