import React from 'react';
import { ChartDimensions } from './Util';

interface ILoadingChart {
  chartDimensions?: ChartDimensions;
}
export const LoadingChart: React.FC<ILoadingChart> = ({
  chartDimensions = {
    height: 48,
    width: 300,
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
}) => {
  const { height, width } = chartDimensions;

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <linearGradient id="greyGradient" x1="0%" x2="100%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="rgba(145, 145, 145, 1)" />
          <stop offset="100%" stopColor="rgba(160, 160, 160, 1)" />
        </linearGradient>
      </defs>
      <text
        x={width * 0.5}
        y={height * 0.5}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={48}
        fontWeight={600}
        fill="url(#greyGradient)"
      >
        Loading...
      </text>
    </svg>
  );
};
